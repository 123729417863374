import "bootstrap/dist/css/bootstrap.css";
import Collapse from "react-bootstrap/Collapse";
import { Card, Col, Row, Form, Button } from "react-bootstrap";
import { React, useState, useEffect, Fragment } from "react";
import {
  netural400,
  netural50,
  neturalBlack,
  primaryGrey,
  primaryOrange,
} from "../../constants/color";
import { format } from "date-fns";
import CounterInput from "react-bootstrap-counter";
import { City, State } from "country-state-city";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  EDIT_PORTAL_SERVICE,
  FETCH_SERVICE_FOR_EDIT,
} from "../../services/graphql/services";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IconContext } from "react-icons";
import {
  IoInformationCircleOutline,
  IoLocationOutline,
  IoOptionsOutline,
  IoTimeOutline,
} from "react-icons/io5";
import LoadingSpinner from "../../components/shared/loading-spinner/loading-spinner.component";
import { useParams } from "react-router-dom";
import HeaderSection from "../header/header.component";
import { useNavigate } from "react-router-dom";
import SelectImages from "../../components/select-images/select-images.component";
import SectionTitle from "../../components/section-title/section-title.component";
import CustomButton from "../button/button.component";

const EditService = () => {
  const { serviceId } = useParams();
  const navigate = useNavigate();
  const [openServiceDetail, setOpenServiceDetail] = useState(false);
  const [openTimeDetail, setOpenTimeDetail] = useState(false);
  const [openLocation, setOpenLocation] = useState(false);
  const validated = false;

  const currentDate = new Date();
  const formatedDate = format(currentDate, "yyyy-MM-dd");

  const [name, setName] = useState("");
  const [isNameValid, setIsNameValid] = useState(false);
  const [serviceStartDate, setServiceStartDate] = useState("");
  const [serviceEndDate, setServiceEndDate] = useState("");
  const [serviceDescription, setServiceDescription] = useState("");
  const [isServiceDescriptionValid, setIsServiceDescriptionValid] =
    useState(false);

  const [servicePrice, setServicePrice] = useState("");
  const [isServicePriceValid, setIsServicePriceValid] = useState(false);
  const [serviceCurrency, setServiceCurrency] = useState("");
  const [serviceDiscount, setServiceDiscount] = useState("");
  const [isServiceDiscountValid, setIsServiceDiscountValid] = useState(false);
  const [serviceRestricedUser, setServiceRestricedUser] = useState("0");

  const [serviceNoClient, setServiceNoClient] = useState(1);
  const [serviceMaxClient, setServiceMaxClient] = useState(1);

  const [images, setImages] = useState([]);
  const [isImagesValid, setIsImagesValid] = useState(false);

  const onChange = (imageList) => {
    if (imageList.length > 0) {
      setIsImagesValid(false);
    } else {
      setIsImagesValid(true);
    }
    setImages(imageList);
  };

  const [isSelectedOption, setIsSelectedOption] = useState("");
  const [isSelectedOnlineOption, setIsSelectedOnlineOption] = useState(false);
  const [isSelectedInPersonOption, setIsSelectedInPersonOption] =
    useState(false);
  const [isError, setIsError] = useState("");

  const [allCountry, setAllCountry] = useState([]);
  const [state, setState] = useState([]);
  const [selectedState, setSelectedState] = useState([]);
  const [city, setCity] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);
  let changeCountry = false;

  // Service Duration time
  const [serviceTimeHour, setServiceTimeHour] = useState("");
  const [serviceTimeMin, setServiceTimeMin] = useState("");

  // Break time
  const [breakTimeFrom, setBreakTimeFrom] = useState("0");
  const [breakTimeTo, setBreakTimeTo] = useState("0");

  // Operating days and hours
  // Sunday
  const [isSelectedSunday, setIsSelectedSunday] = useState(false);
  const [operatingSundayFrom, setOperatingSundayFrom] = useState("");
  const [operatingSundayTo, setOperatingSundayTo] = useState("");

  // Monday
  const [isSelectedMonday, setIsSelectedMonday] = useState(false);
  const [operatingMondayFrom, setOperatingMondayFrom] = useState("");
  const [operatingMondayTo, setOperatingMondayTo] = useState("");

  // Tuesday
  const [isSelectedTuesday, setIsSelectedTuesday] = useState(false);
  const [operatingTuesdayFrom, setOperatingTuesdayFrom] = useState("");
  const [operatingTuesdayTo, setOperatingTuesdayTo] = useState("");

  // Wednesday
  const [isSelectedWednesday, setIsSelectedWednesday] = useState(false);
  const [operatingWednesdayFrom, setOperatingWednesdayFrom] = useState("");
  const [operatingWednesdayTo, setOperatingWednesdayTo] = useState("");

  // Thursday
  const [isSelectedThursday, setIsSelectedThursday] = useState(false);
  const [operatingThursdayFrom, setOperatingThursdayFrom] = useState("");
  const [operatingThursdayTo, setOperatingThursdayTo] = useState("");

  // Friday
  const [isSelectedFriday, setIsSelectedFriday] = useState(false);
  const [operatingFridayFrom, setOperatingFridayFrom] = useState("");
  const [operatingFridayTo, setOperatingFridayTo] = useState("");

  // Saturday
  const [isSelectedSaturday, setIsSelectedSaturday] = useState(false);
  const [operatingSaturdayFrom, setOperatingSaturdayFrom] = useState("");
  const [operatingSaturdayTo, setOperatingSaturdayTo] = useState("");

  useEffect(() => {
    if (allCountry.length !== 0) {
      getState();
    } else {
      setSelectedState([]);
      setSelectedCity([]);
      changeCountry = true;
    }
  }, [allCountry, changeCountry]);

  useEffect(() => {
    if (selectedState.length !== 0) {
      getCity();
    } else {
      setSelectedCity([]);
    }
  }, [selectedState]);

  const getState = () => {
    if (allCountry.length !== 0) {
      setState(State.getStatesOfCountry(allCountry[0].isoCode));
    }
  };
  const getCity = () => {
    if (!changeCountry) {
      if (selectedState.length !== 0) {
        setCity(
          City.getCitiesOfState(allCountry[0].isoCode, selectedState[0].isoCode)
        );
      }
    }
  };

  const [editPortalService, { loading }] = useMutation(EDIT_PORTAL_SERVICE, {
    onCompleted: (data) => {
      setOpenServiceDetail(!openServiceDetail);
      toast.success(data.editPortalService.message, {
        style: { width: "450px" },
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored ",
      });
      navigate("/services");
    },
    onError: (error) => {
     // console.log(error, "ERROR==============");
      toast.error(error.message, {
        style: { width: "450px" },
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored ",
      });
    },
  });

  const handleSubmit = async () => {
    if (name && name === "") {
      setIsNameValid(true);
    } else if (serviceDescription && serviceDescription === "") {
      setIsServiceDescriptionValid(true);
    } else if (servicePrice && servicePrice === "") {
      setIsServicePriceValid(true);
    } else if (serviceDiscount && serviceDiscount === "") {
      setIsServiceDiscountValid(true);
    } else if (images && images.length === 0) {
      setIsImagesValid(true);
    } else {
      let medias = [];
      images.map((imageValue, index) => {
        if (imageValue.height) {
          medias.push({
            ...imageValue,
            key: index,
          });
        } else {
          let media = {
            height: 720,
            width: 1080,
            mediaType: "photo",
            key: index,
            uri: "uri",
            file: imageValue.file,
          };
          medias.push(media);
        }
      });

      let finalData = {
        serviceId,
        title: name,
        description: serviceDescription,
        startDate: serviceStartDate,
        endDate: serviceEndDate,
        price: parseFloat(servicePrice),
        currency: serviceCurrency,
        discount: parseFloat(serviceDiscount),
        bookingRestriction: parseInt(serviceRestricedUser),
        capacity: serviceNoClient,
        maxCapacity: serviceMaxClient,
        medias: medias,
      };
      // console.log("All data-------", finalData);

      await editPortalService({
        variables: {
          editServiceInput: finalData,
        },
      });
    }
  };
  const [fetchServiceForEdit, { loading: loadingFetchServiceForEdit }] =
    useLazyQuery(FETCH_SERVICE_FOR_EDIT, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
       // console.log("DATA=======", data);
        let fetchService = data.fetchServiceForEdit.service;
        if (data && data.fetchServiceForEdit.message === "Service found") {
          setName(fetchService.title);
          setServiceStartDate(fetchService.startDate);
          setServiceEndDate(fetchService.endDate);
          setServiceDescription(fetchService.description);
          setServicePrice(fetchService.price);
          setServiceCurrency(fetchService.currency);
          setServiceDiscount(fetchService.discount);
          setServiceRestricedUser(fetchService.bookingRestriction.toString());
          setServiceNoClient(fetchService.capacity);
          setServiceMaxClient(fetchService.maxCapacity);

          if (fetchService.medias?.length > 0) {
            let mediaArray = fetchService.medias;
            let tempMediaArray = [];
            tempMediaArray = mediaArray.map(({ __typename, ...rest }) => rest);
            //console.log("Value--------------", tempMediaArray);
            setImages(tempMediaArray);
          }

          setServiceTimeHour(fetchService.duration.hours.toString());
          setServiceTimeMin(fetchService.duration.minutes.toString());

          setBreakTimeFrom(fetchService.breakTime.startTime);
          setBreakTimeTo(fetchService.breakTime.endTime);

          fetchService.schedule.map((schedule) => {
            if (schedule.available) {
              if (schedule.dayOfWeek === "Sunday") {
                setIsSelectedSunday(true);
                setOperatingSundayFrom(schedule.startTime);
                setOperatingSundayTo(schedule.endTime);
              } else if (schedule.dayOfWeek === "Monday") {
                setIsSelectedMonday(true);
                setOperatingMondayFrom(schedule.startTime);
                setOperatingMondayTo(schedule.endTime);
              } else if (schedule.dayOfWeek === "Tuesday") {
                setIsSelectedTuesday(true);
                setOperatingTuesdayFrom(schedule.startTime);
                setOperatingTuesdayTo(schedule.endTime);
              } else if (schedule.dayOfWeek === "Wednesday") {
                setIsSelectedWednesday(true);
                setOperatingWednesdayFrom(schedule.startTime);
                setOperatingWednesdayTo(schedule.endTime);
              } else if (schedule.dayOfWeek === "Thursday") {
                setIsSelectedThursday(true);
                setOperatingThursdayFrom(schedule.startTime);
                setOperatingThursdayTo(schedule.endTime);
              } else if (schedule.dayOfWeek === "Friday") {
                setIsSelectedFriday(true);
                setOperatingFridayFrom(schedule.startTime);
                setOperatingFridayTo(schedule.endTime);
              } else if (schedule.dayOfWeek === "Saturday") {
                setIsSelectedSaturday(true);
                setOperatingSaturdayFrom(schedule.startTime);
                setOperatingSaturdayTo(schedule.endTime);
              }
            }
          });

          setIsSelectedOption(fetchService.serviceType);
          setIsSelectedInPersonOption(fetchService.serviceInPerson);
          setIsSelectedOnlineOption(fetchService.serviceOnline);

          setAllCountry(fetchService.location[0].countryName);
          setSelectedState(fetchService.location[0].stateName);
          setSelectedCity(fetchService.location[0].cityName);
        }
      },
      onError: (error) => {
        toast.error(error.message, {
          style: { width: "450px" },
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored ",
        });
        // NAVIAGATE TO PAGE NOT FOUND ROUTE,
        navigate("/services");
      },
    });
  const getServiceData = async (serviceId) => {
    await fetchServiceForEdit({
      variables: {
        serviceId,
      },
    });
  };

  useEffect(() => {
    getServiceData(serviceId);
  }, [serviceId]);

  const invertServiceDetailsSection = () =>
    setOpenServiceDetail(!openServiceDetail);

  return loadingFetchServiceForEdit ? (
    <LoadingSpinner />
  ) : (
    <div className="main-div-right-section">
      <Fragment>
        <HeaderSection currentTab={"Service"} />
        <div className="second-section-style">
          <SectionTitle title={name} />
          <Card
            style={{
              width: "100%",
              borderTopLeftRadius: "12px",
              borderTopRightRadius: "12px",
            }}
            aria-expanded={openServiceDetail}
            aria-controls="collapseID"
            className="shadow"
          >
            <div>
              <Row>
                <Col
                  lg="1"
                  md="1"
                  sm="1"
                  xs="1"
                  className="mt-3 mb-3"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => setOpenServiceDetail(!openServiceDetail)}
                >
                  <IconContext.Provider value={{ size: 24, color: "black" }}>
                    <IoOptionsOutline />
                  </IconContext.Provider>
                </Col>
                <Col
                  lg="8"
                  md="8"
                  sm="8"
                  xs="8"
                  className="mt-3 mb-3"
                  style={{ flexDirection: "column", display: "flex" }}
                  onClick={() => setOpenServiceDetail(!openServiceDetail)}
                >
                  <label
                    style={{
                      fontWeight: "500",
                      fontSize: "16px",
                      color: neturalBlack,
                    }}
                  >
                    Service details
                  </label>
                  <label
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      color: netural400,
                    }}
                  >
                    {name}
                  </label>
                </Col>
                {openServiceDetail ? (
                  <Fragment>
                    <Col
                      lg="1"
                      md="1"
                      sm="1"
                      xs="1"
                      className="mt-3 mb-3"
                      style={{ flexDirection: "column", display: "flex" }}
                    >
                      <CustomButton
                        buttonType="secondary"
                        title={"Cancel"}
                        handleButtonClick={invertServiceDetailsSection}
                      />
                    </Col>
                    <Col
                      lg="2"
                      md="2"
                      sm="2"
                      xs="2"
                      className="mt-3 mb-3"
                      style={{ flexDirection: "column", display: "flex" }}
                    >
                      <CustomButton
                        title={"Save and close"}
                        customStyle={{ marginTop: "0px", padding: "8px" }}
                        handleButtonClick={handleSubmit}
                      />
                    </Col>
                  </Fragment>
                ) : null}
              </Row>
            </div>
          </Card>
          <Collapse in={openServiceDetail}>
            <div id="collapseID" style={{ marginBottom: "3%" }}>
              <Card
                style={{
                  width: "100%",
                  borderBottomLeftRadius: "12px",
                  borderBottomRightRadius: "12px",
                }}
              >
                <div style={{ marginBottom: "3%" }}>
                  <SectionTitle
                    title={"General Information"}
                    titleStyle={{ marginLeft: "3%" }}
                  />
                  <div
                    style={{
                      marginLeft: "2%",
                    }}
                  >
                    <Form noValidate validated={validated}>
                      <Row className="mb-3">
                        <Col md="6" lg="6">
                          <Col md="12" lg="12">
                            <Form.Group style={{ color: "#A6A6A6" }}>
                              <Form.Label>Name</Form.Label>
                              <Form.Control
                                required
                                type="text"
                                placeholder="Name"
                                value={name}
                                onChange={(value) => {
                                  if (value.target.value.trim().length !== 0) {
                                    setName(value.target.value);
                                    setIsNameValid(false);
                                  } else {
                                    setIsNameValid(true);
                                    setName("");
                                  }
                                }}
                              />
                              {isNameValid ? (
                                <label
                                  style={{
                                    fontSize: ".875em",
                                    color: "#dc3545",
                                    display: "flex",
                                    justifyContent: "center",
                                    marginTop: "2%",
                                  }}
                                >
                                  Please provide a valid service name.
                                </label>
                              ) : null}
                            </Form.Group>
                          </Col>
                          <div style={{ display: "flex", marginTop: "20px" }}>
                            <Col
                              md="6"
                              style={{ color: "#A6A6A6", paddingRight: "10px" }}
                            >
                              <Form.Group>
                                <Form.Label>
                                  Start service date {formatedDate}
                                </Form.Label>
                                <Form.Control
                                  required
                                  type="date"
                                  min={formatedDate}
                                  pattern="yyy/mm/dd"
                                  placeholder="yyyy/mm/dd"
                                  value={serviceStartDate}
                                  onChange={(value) => {
                                    setServiceStartDate(value.target.value);
                                  }}
                                />
                                <Form.Control.Feedback type="invalid">
                                  Please provide a valid start service date.
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>

                            <Col md="6" style={{ color: "#A6A6A6" }}>
                              <Form.Group>
                                <Form.Label>End service date</Form.Label>
                                <Form.Control
                                  required
                                  type="date"
                                  min={serviceStartDate}
                                  pattern="yyy/mm/dd"
                                  placeholder="yyyy/mm/dd"
                                  value={serviceEndDate}
                                  onChange={(value) => {
                                    setServiceEndDate(value.target.value);
                                  }}
                                />
                                <Form.Control.Feedback type="invalid">
                                  Please provide a valid end service date.
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                          </div>
                          <Col
                            md="12"
                            style={{ color: "#A6A6A6", marginTop: "20px" }}
                          >
                            <Form.Group>
                              <Form.Label>Service description</Form.Label>
                              <Form.Control
                                required
                                as="textarea"
                                rows={7}
                                maxLength={1000}
                                placeholder="Service description"
                                value={serviceDescription}
                                onChange={(value) => {
                                  if (value.target.value.trim().length !== 0) {
                                    setServiceDescription(value.target.value);
                                    setIsServiceDescriptionValid(false);
                                  } else {
                                    setIsServiceDescriptionValid(true);
                                    setServiceDescription("");
                                  }
                                }}
                              />
                            </Form.Group>
                            {isServiceDescriptionValid ? (
                              <label
                                style={{
                                  fontSize: ".875em",
                                  color: "#dc3545",
                                  display: "flex",
                                  justifyContent: "center",
                                  marginTop: "2%",
                                }}
                              >
                                Please provide a valid service description.
                              </label>
                            ) : null}
                          </Col>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </div>
                <div
                  style={{ height: "1px", backgroundColor: primaryGrey }}
                ></div>
                <div style={{ marginBottom: "3%" }}>
                  <SectionTitle
                    title={"Pricing"}
                    titleStyle={{ marginLeft: "3%" }}
                  />
                  <div
                    style={{
                      marginLeft: "2%",
                    }}
                  >
                    <Form noValidate validated={validated}>
                      <Row className="mb-3">
                        <Col md="6" lg="6">
                          <Col md="12" lg="12">
                            <Form.Group style={{ color: "#A6A6A6" }}>
                              <Form.Label>Price per service</Form.Label>
                              <Form.Control
                                required
                                type="number"
                                placeholder="Price per service"
                                style={{ borderRadius: "12px" }}
                                value={servicePrice}
                                onChange={(value) => {
                                  if (
                                    value.target.value.match("^[0-9 ]") != null
                                  ) {
                                    setServicePrice(value.target.value);
                                    setIsServicePriceValid(false);
                                  } else {
                                    setServicePrice("");
                                    setIsServicePriceValid(true);
                                  }
                                }}
                                step={"0.01"}
                              />
                            </Form.Group>
                            {isServicePriceValid ? (
                              <label
                                style={{
                                  fontSize: ".875em",
                                  color: "#dc3545",
                                  display: "flex",
                                  justifyContent: "center",
                                  marginTop: "2%",
                                }}
                              >
                                Please provide a valid Price.
                              </label>
                            ) : null}
                          </Col>
                          <div style={{ display: "flex", marginTop: "20px" }}>
                            <Col md="12" lg="12">
                              <Form.Group style={{ color: "#A6A6A6" }}>
                                <Form.Label>Currency</Form.Label>
                                <Form.Select
                                  aria-label="Default select example"
                                  style={{ borderRadius: "12px" }}
                                  onChange={(value) => {
                                    setServiceCurrency(value.target.value);
                                  }}
                                >
                                  {/* <option
                                    selected={
                                      serviceCurrency === "CAD" ? true : false
                                    }
                                    value="CAD"
                                  >
                                    CAD Canadian Dollar
                                  </option> */}
                                  <option
                                    selected={
                                      serviceCurrency === "USD" ? true : false
                                    }
                                    value="USD"
                                  >
                                    USD
                                  </option>
                                  {/* <option
                                    selected={
                                      serviceCurrency === "INR" ? true : false
                                    }
                                    value="INR"
                                  >
                                    INR
                                  </option> */}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                  Please provide a valid currency.
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                          </div>
                          <div style={{ display: "flex", marginTop: "20px" }}>
                            <Col md="12" lg="12">
                              <Form.Group style={{ color: "#A6A6A6" }}>
                                <Form.Label>Discount</Form.Label>
                                <Form.Control
                                  required
                                  type="number"
                                  placeholder="Discount"
                                  style={{ borderRadius: "12px" }}
                                  value={serviceDiscount}
                                  onChange={(event) => {
                                    const newValue = event.target.value;
                                    if (
                                      newValue === "" ||
                                      (newValue >= 0 && newValue <= 100)
                                    ) {
                                      setServiceDiscount(newValue);
                                      setIsServiceDiscountValid(false);
                                    } else {
                                      setServiceDiscount(newValue);
                                      setIsServiceDiscountValid(true);
                                    }
                                  }}
                                  step={"0.01"}
                                />
                              </Form.Group>
                              {isServiceDiscountValid ? (
                                <label
                                  style={{
                                    fontSize: ".875em",
                                    color: "#dc3545",
                                    display: "flex",
                                    justifyContent: "center",
                                    marginTop: "2%",
                                  }}
                                >
                                  {serviceDiscount === ""
                                    ? "Please provide a valid Discount."
                                    : "Discount must be between 0 and 100"}
                                </label>
                              ) : null}
                            </Col>
                          </div>
                          <div style={{ display: "flex", marginTop: "20px" }}>
                            <Col md="12" lg="12">
                              <Form.Group
                                controlId="validationCustom01"
                                style={{ color: "#A6A6A6" }}
                              >
                                <Form.Label>
                                  Units restricted per user
                                </Form.Label>
                                <Form.Select
                                  aria-label="Default select example"
                                  style={{ borderRadius: "12px" }}
                                  onChange={(value) => {
                                    setServiceRestricedUser(value.target.value);
                                  }}
                                  disabled
                                >
                                  <option
                                    selected={
                                      serviceRestricedUser === "0"
                                        ? true
                                        : false
                                    }
                                    value="0"
                                    disabled
                                  >
                                    Select any one if required.
                                  </option>
                                  <option
                                    selected={
                                      serviceRestricedUser === "1"
                                        ? true
                                        : false
                                    }
                                    value="1"
                                  >
                                    Once
                                  </option>
                                  <option
                                    selected={
                                      serviceRestricedUser === "2"
                                        ? true
                                        : false
                                    }
                                    value="2"
                                  >
                                    Twice
                                  </option>
                                  <option
                                    selected={
                                      serviceRestricedUser === "3"
                                        ? true
                                        : false
                                    }
                                    value="3"
                                  >
                                    Thrice
                                  </option>
                                  <option
                                    selected={
                                      serviceRestricedUser === "4"
                                        ? true
                                        : false
                                    }
                                    value="4"
                                  >
                                    Four times
                                  </option>
                                  <option
                                    selected={
                                      serviceRestricedUser === "5"
                                        ? true
                                        : false
                                    }
                                    value="5"
                                  >
                                    Five times
                                  </option>
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                  Please provide a valid currency.
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </div>
                <div
                  style={{ height: "1px", backgroundColor: primaryGrey }}
                ></div>
                <div style={{ marginBottom: "3%" }}>
                  <SectionTitle
                    title={"Determine number of clients"}
                    titleStyle={{ marginLeft: "3%" }}
                  />
                  <div
                    style={{
                      marginLeft: "2%",
                    }}
                  >
                    <Form noValidate validated={validated}>
                      <Row className="mb-3">
                        <Col md="6" lg="6">
                          <Col md="12" lg="12">
                            <Form.Group style={{ color: "#A6A6A6" }}>
                              <Form.Label>
                                Number of clients at the same time per booking
                              </Form.Label>
                              <CounterInput
                                value={serviceNoClient}
                                min={1}
                                max={50}
                                onChange={(value) => {
                                  setServiceNoClient(value);
                                }}
                                key={serviceNoClient}
                              />
                              <Form.Control.Feedback>
                                Looks good!
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <div style={{ display: "flex", marginTop: "20px" }}>
                            <Col md="12" lg="12">
                              <Form.Group style={{ color: "#A6A6A6" }}>
                                <Form.Label>
                                  Maximum capacity of clients for this service
                                  (if applicable)
                                </Form.Label>
                                <CounterInput
                                  min={1}
                                  max={50}
                                  onChange={(value) => {
                                    setServiceMaxClient(value);
                                  }}
                                  value={serviceMaxClient}
                                  key={serviceMaxClient}
                                />
                                <Col
                                  md="12"
                                  lg="12"
                                  style={{ display: "flex", marginTop: "1%" }}
                                >
                                  <IconContext.Provider
                                    value={{ size: 22, color: "black" }}
                                  >
                                    <IoInformationCircleOutline />
                                  </IconContext.Provider>
                                  <label
                                    style={{
                                      fontSize: "12px",
                                      marginLeft: "1%",
                                    }}
                                  >
                                    Only if service has full capacity. E.g. trip
                                    tours, travel buses, etc.
                                  </label>
                                </Col>
                                <Form.Control.Feedback>
                                  Looks good!
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </div>
                <div
                  style={{ height: "1px", backgroundColor: primaryGrey }}
                ></div>
                <div style={{ marginBottom: "3%" }}>
                  <SectionTitle
                    title={"Service images"}
                    subtitle={"Upload up to 7 images"}
                    titleStyle={{ marginLeft: "3%" }}
                  />
                  <div
                    style={{
                      marginLeft: "2%",
                    }}
                  >
                    <Row className="mb-3">
                      <Col md="12" lg="6" sm="12" xs="12">
                        <Col md="12" lg="12" sm="12" xs="12">
                          <SelectImages
                            images={images}
                            onChange={onChange}
                            isGeneralError={isImagesValid}
                          />
                        </Col>
                      </Col>
                    </Row>
                  </div>
                </div>
                <div
                  style={{ height: "1px", backgroundColor: primaryGrey }}
                ></div>
                <Fragment>
                  <Row style={{ justifyContent: "end" }}>
                    <Col
                      lg="1"
                      md="1"
                      sm="1"
                      xs="1"
                      className="mt-3 mb-3"
                      style={{ flexDirection: "column", display: "flex" }}
                    >
                      <CustomButton
                        buttonType="secondary"
                        title={"Cancel"}
                        handleButtonClick={invertServiceDetailsSection}
                      />
                    </Col>
                    <Col
                      lg="2"
                      md="2"
                      sm="2"
                      xs="2"
                      className="mt-3 mb-3"
                      style={{ flexDirection: "column", display: "flex" }}
                    >
                      <CustomButton
                        title={"Save and close"}
                        customStyle={{ marginTop: "0px", padding: "8px" }}
                        handleButtonClick={handleSubmit}
                      />
                    </Col>
                  </Row>
                </Fragment>
              </Card>
            </div>
          </Collapse>
          <Card
            style={{
              width: "100%",
              borderTopLeftRadius: "12px",
              borderTopRightRadius: "12px",
              marginTop: "2%",
            }}
            onClick={() => setOpenTimeDetail(!openTimeDetail)}
            aria-expanded={openTimeDetail}
            aria-controls="collapseID2"
            className="shadow"
          >
            <div>
              <Row>
                <Col
                  lg="1"
                  md="1"
                  sm="1"
                  xs="1"
                  className="mt-3 mb-3"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <IconContext.Provider value={{ size: 24, color: "black" }}>
                    <IoTimeOutline />
                  </IconContext.Provider>
                </Col>
                <Col
                  lg="8"
                  md="8"
                  sm="8"
                  xs="8"
                  className="mt-3 mb-3"
                  style={{ flexDirection: "column", display: "flex" }}
                >
                  <label
                    style={{
                      fontWeight: "500",
                      fontSize: "16px",
                      color: neturalBlack,
                    }}
                  >
                    Time details
                  </label>
                  <label
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      color: netural400,
                    }}
                  >
                    {parseInt(serviceTimeHour) > 1
                      ? `${serviceTimeHour} hours`
                      : `${serviceTimeHour} hour`}{" "}
                    {parseInt(serviceTimeMin) > 1
                      ? `${serviceTimeMin} minutes`
                      : ""}
                  </label>
                </Col>
              </Row>
            </div>
          </Card>
          <Collapse in={openTimeDetail}>
            <div id="collapseID2" style={{ marginBottom: "3%" }}>
              <Card
                style={{
                  width: "100%",
                  borderBottomLeftRadius: "12px",
                  borderBottomRightRadius: "12px",
                }}
              >
                <div
                  style={{
                    marginTop: "2%",
                    backgroundColor: "#FFF3DA",
                    padding: 16,
                    marginRight: "5%",
                    marginLeft: "5%",
                  }}
                >
                  <label
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: netural400,
                      marginLeft: 10,
                    }}
                  >
                    Edit this section is not currently possible. You can always
                    create other services in case of changing schedules.
                  </label>
                </div>
                <div style={{ marginBottom: "3%" }}>
                  <SectionTitle
                    title={"Service duration time"}
                    titleStyle={{ marginLeft: "3%" }}
                  />
                </div>
                <div style={{ marginBottom: "3%" }}>
                  <div
                    style={{
                      marginLeft: "2%",
                    }}
                  >
                    <Form noValidate validated={validated}>
                      <Row>
                        <Col lg="6" md="6" sm="6" xs="6">
                          <div style={{ display: "flex" }}>
                            <Col
                              md="12"
                              style={{ color: "#A6A6A6", paddingRight: "10px" }}
                            >
                              <Form.Group style={{ color: "#A6A6A6" }}>
                                <Form.Label style={{ color: netural400 }}>
                                  Duration service time
                                </Form.Label>
                                <div style={{ display: "flex" }}>
                                  <Col
                                    md="6"
                                    style={{
                                      color: "#A6A6A6",
                                      paddingRight: "2%",
                                    }}
                                  >
                                    <Form.Select
                                      aria-label="Default select example"
                                      style={{ borderRadius: "12px" }}
                                      required
                                      disabled
                                    >
                                      <option value="00">From</option>
                                      <option
                                        selected={
                                          serviceTimeHour === "0" ? true : false
                                        }
                                        value="0"
                                      >
                                        0 hour
                                      </option>
                                      <option
                                        selected={
                                          serviceTimeHour === "1" ? true : false
                                        }
                                        value="1"
                                      >
                                        1 hour
                                      </option>
                                      <option
                                        selected={
                                          serviceTimeHour === "2" ? true : false
                                        }
                                        value="2"
                                      >
                                        2 hour
                                      </option>
                                      <option
                                        selected={
                                          serviceTimeHour === "3" ? true : false
                                        }
                                        value="3"
                                      >
                                        3 hour
                                      </option>
                                      <option
                                        selected={
                                          serviceTimeHour === "4" ? true : false
                                        }
                                        value="4"
                                      >
                                        4 hour
                                      </option>
                                      <option
                                        selected={
                                          serviceTimeHour === "8" ? true : false
                                        }
                                        value="8"
                                      >
                                        8 hour
                                      </option>
                                      <option
                                        selected={
                                          serviceTimeHour === "10"
                                            ? true
                                            : false
                                        }
                                        value="10"
                                      >
                                        10 hour
                                      </option>
                                      <option
                                        selected={
                                          serviceTimeHour === "12"
                                            ? true
                                            : false
                                        }
                                        value="12"
                                      >
                                        12 hour
                                      </option>
                                      <option
                                        selected={
                                          serviceTimeHour === "14"
                                            ? true
                                            : false
                                        }
                                        value="14"
                                      >
                                        14 hour
                                      </option>
                                    </Form.Select>
                                  </Col>
                                  <Col
                                    md="6"
                                    style={{
                                      color: "#A6A6A6",
                                      paddingLeft: "2%",
                                    }}
                                  >
                                    <Form.Select
                                      aria-label="Default select example"
                                      style={{ borderRadius: "12px" }}
                                      required
                                      disabled
                                    >
                                      <option value="00">To</option>
                                      <option
                                        selected={
                                          serviceTimeMin === "0" ? true : false
                                        }
                                        disabled={
                                          serviceTimeHour === "0" ? true : false
                                        }
                                        value="0"
                                      >
                                        0 minutes
                                      </option>
                                      <option
                                        selected={
                                          serviceTimeMin === "15" ? true : false
                                        }
                                        disabled={
                                          serviceTimeHour === "14"
                                            ? true
                                            : false
                                        }
                                        value="15"
                                      >
                                        15 minutes
                                      </option>
                                      <option
                                        selected={
                                          serviceTimeMin === "30" ? true : false
                                        }
                                        disabled={
                                          serviceTimeHour === "14"
                                            ? true
                                            : false
                                        }
                                        value="30"
                                      >
                                        30 minutes
                                      </option>
                                      <option
                                        selected={
                                          serviceTimeMin === "45" ? true : false
                                        }
                                        disabled={
                                          serviceTimeHour === "14"
                                            ? true
                                            : false
                                        }
                                        value="45"
                                      >
                                        45 minutes
                                      </option>
                                    </Form.Select>
                                  </Col>
                                </div>
                              </Form.Group>
                            </Col>
                          </div>
                          <div style={{ display: "flex", marginTop: "20px" }}>
                            <Col
                              md="12"
                              style={{ color: "#A6A6A6", paddingRight: "10px" }}
                            >
                              <Form.Group style={{ color: "#A6A6A6" }}>
                                <Form.Label style={{ color: netural400 }}>
                                  Break Time
                                </Form.Label>
                                <div style={{ display: "flex" }}>
                                  <Col
                                    md="6"
                                    style={{
                                      color: "#A6A6A6",
                                      paddingRight: "2%",
                                    }}
                                  >
                                    <Form.Select
                                      aria-label="Default select example"
                                      style={{ borderRadius: "12px" }}
                                      disabled
                                    >
                                      <option value="00">From</option>
                                      <option
                                        selected={
                                          breakTimeFrom === "9" ? true : false
                                        }
                                        value="9"
                                      >
                                        9:00
                                      </option>
                                      <option
                                        selected={
                                          breakTimeFrom === "10" ? true : false
                                        }
                                        value="10"
                                      >
                                        10:00
                                      </option>
                                      <option
                                        selected={
                                          breakTimeFrom === "11" ? true : false
                                        }
                                        value="11"
                                      >
                                        11:00
                                      </option>
                                      <option
                                        selected={
                                          breakTimeFrom === "12" ? true : false
                                        }
                                        value="12"
                                      >
                                        12:00
                                      </option>
                                      <option
                                        selected={
                                          breakTimeFrom === "13" ? true : false
                                        }
                                        value="13"
                                      >
                                        13:00
                                      </option>
                                      <option
                                        selected={
                                          breakTimeFrom === "14" ? true : false
                                        }
                                        value="14"
                                      >
                                        14:00
                                      </option>
                                      <option
                                        selected={
                                          breakTimeFrom === "15" ? true : false
                                        }
                                        value="15"
                                      >
                                        15:00
                                      </option>
                                      <option
                                        selected={
                                          breakTimeFrom === "16" ? true : false
                                        }
                                        value="16"
                                      >
                                        16:00
                                      </option>
                                      <option
                                        selected={
                                          breakTimeFrom === "17" ? true : false
                                        }
                                        value="17"
                                      >
                                        17:00
                                      </option>
                                      <option
                                        selected={
                                          breakTimeFrom === "18" ? true : false
                                        }
                                        value="18"
                                      >
                                        18:00
                                      </option>
                                      <option
                                        selected={
                                          breakTimeFrom === "19" ? true : false
                                        }
                                        value="19"
                                      >
                                        19:00
                                      </option>
                                      <option
                                        selected={
                                          breakTimeFrom === "20" ? true : false
                                        }
                                        value="20"
                                      >
                                        20:00
                                      </option>
                                    </Form.Select>
                                  </Col>
                                  <Col
                                    md="6"
                                    style={{
                                      color: "#A6A6A6",
                                      paddingLeft: "2%",
                                    }}
                                  >
                                    <Form.Select
                                      aria-label="Default select example"
                                      style={{ borderRadius: "12px" }}
                                      disabled
                                    >
                                      <option value="00">To</option>
                                      <option
                                        selected={
                                          breakTimeTo === "9" ? true : false
                                        }
                                        disabled={
                                          parseInt(breakTimeFrom) >= 9
                                            ? true
                                            : false
                                        }
                                        value="9"
                                      >
                                        9:00
                                      </option>
                                      <option
                                        selected={
                                          breakTimeTo === "10" ? true : false
                                        }
                                        disabled={
                                          parseInt(breakTimeFrom) >= 10
                                            ? true
                                            : false
                                        }
                                        value="10"
                                      >
                                        10:00
                                      </option>
                                      <option
                                        selected={
                                          breakTimeTo === "11" ? true : false
                                        }
                                        disabled={
                                          parseInt(breakTimeFrom) >= 11
                                            ? true
                                            : false
                                        }
                                        value="11"
                                      >
                                        11:00
                                      </option>
                                      <option
                                        selected={
                                          breakTimeTo === "12" ? true : false
                                        }
                                        disabled={
                                          parseInt(breakTimeFrom) >= 12
                                            ? true
                                            : false
                                        }
                                        value="12"
                                      >
                                        12:00
                                      </option>
                                      <option
                                        selected={
                                          breakTimeTo === "13" ? true : false
                                        }
                                        disabled={
                                          parseInt(breakTimeFrom) >= 13
                                            ? true
                                            : false
                                        }
                                        value="13"
                                      >
                                        13:00
                                      </option>
                                      <option
                                        selected={
                                          breakTimeTo === "14" ? true : false
                                        }
                                        disabled={
                                          parseInt(breakTimeFrom) >= 14
                                            ? true
                                            : false
                                        }
                                        value="14"
                                      >
                                        14:00
                                      </option>
                                      <option
                                        selected={
                                          breakTimeTo === "15" ? true : false
                                        }
                                        disabled={
                                          parseInt(breakTimeFrom) >= 15
                                            ? true
                                            : false
                                        }
                                        value="15"
                                      >
                                        15:00
                                      </option>
                                      <option
                                        selected={
                                          breakTimeTo === "16" ? true : false
                                        }
                                        disabled={
                                          parseInt(breakTimeFrom) >= 16
                                            ? true
                                            : false
                                        }
                                        value="16"
                                      >
                                        16:00
                                      </option>
                                      <option
                                        selected={
                                          breakTimeTo === "17" ? true : false
                                        }
                                        disabled={
                                          parseInt(breakTimeFrom) >= 17
                                            ? true
                                            : false
                                        }
                                        value="17"
                                      >
                                        17:00
                                      </option>
                                      <option
                                        selected={
                                          breakTimeTo === "18" ? true : false
                                        }
                                        disabled={
                                          parseInt(breakTimeFrom) >= 18
                                            ? true
                                            : false
                                        }
                                        value="18"
                                      >
                                        18:00
                                      </option>
                                      <option
                                        selected={
                                          breakTimeTo === "19" ? true : false
                                        }
                                        disabled={
                                          parseInt(breakTimeFrom) >= 19
                                            ? true
                                            : false
                                        }
                                        value="19"
                                      >
                                        19:00
                                      </option>
                                      <option
                                        selected={
                                          breakTimeTo === "20" ? true : false
                                        }
                                        disabled={
                                          parseInt(breakTimeFrom) >= 20
                                            ? true
                                            : false
                                        }
                                        value="20"
                                      >
                                        20:00
                                      </option>
                                    </Form.Select>
                                  </Col>
                                </div>
                              </Form.Group>
                            </Col>
                          </div>
                          <div style={{ display: "flex", marginTop: "20px" }}>
                            <Col md="12" lg="12" sm="12">
                              <Form.Group style={{ color: "#A6A6A6" }}>
                                <Form.Label style={{ color: netural400 }}>
                                  Operating days and hours
                                </Form.Label>
                                {/* Sunday */}
                                <Col
                                  md="12"
                                  style={{
                                    color: "#A6A6A6",
                                    paddingRight: "10px",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Col lg="2" md="" sm="">
                                    {["checkbox"].map((type) => (
                                      <div
                                        key={`inline-${type}`}
                                        className="mb-3"
                                      >
                                        <Col
                                          lg="12"
                                          md="12"
                                          sm="12"
                                          xs="12"
                                          style={{ marginTop: 10 }}
                                        >
                                          <Form.Check
                                            inline
                                            label="Sun"
                                            name="Sunday"
                                            type={type}
                                            id={`inline-${type}-2`}
                                            style={{ color: netural400 }}
                                            checked={
                                              isSelectedSunday ? true : false
                                            }
                                            disabled
                                          />
                                        </Col>
                                      </div>
                                    ))}
                                  </Col>
                                  <Col
                                    lg="5"
                                    md=""
                                    sm=""
                                    style={{ paddingRight: "10px" }}
                                  >
                                    <Form.Select
                                      aria-label="Default select example"
                                      style={{ borderRadius: "12px" }}
                                      disabled
                                    >
                                      <option value="0">From</option>
                                      <option
                                        selected={
                                          operatingSundayFrom === "6"
                                            ? true
                                            : false
                                        }
                                        value="6"
                                      >
                                        6:00
                                      </option>
                                      <option
                                        selected={
                                          operatingSundayFrom === "7"
                                            ? true
                                            : false
                                        }
                                        value="7"
                                      >
                                        7:00
                                      </option>
                                      <option
                                        selected={
                                          operatingSundayFrom === "8"
                                            ? true
                                            : false
                                        }
                                        value="8"
                                      >
                                        8:00
                                      </option>
                                      <option
                                        selected={
                                          operatingSundayFrom === "9"
                                            ? true
                                            : false
                                        }
                                        value="9"
                                      >
                                        9:00
                                      </option>
                                      <option
                                        selected={
                                          operatingSundayFrom === "10"
                                            ? true
                                            : false
                                        }
                                        value="10"
                                      >
                                        10:00
                                      </option>
                                      <option
                                        selected={
                                          operatingSundayFrom === "11"
                                            ? true
                                            : false
                                        }
                                        value="11"
                                      >
                                        11:00
                                      </option>
                                      <option
                                        selected={
                                          operatingSundayFrom === "12"
                                            ? true
                                            : false
                                        }
                                        value="12"
                                      >
                                        12:00
                                      </option>
                                      <option
                                        selected={
                                          operatingSundayFrom === "13"
                                            ? true
                                            : false
                                        }
                                        value="13"
                                      >
                                        13:00
                                      </option>
                                      <option
                                        selected={
                                          operatingSundayFrom === "14"
                                            ? true
                                            : false
                                        }
                                        value="14"
                                      >
                                        14:00
                                      </option>
                                      <option
                                        selected={
                                          operatingSundayFrom === "15"
                                            ? true
                                            : false
                                        }
                                        value="15"
                                      >
                                        15:00
                                      </option>
                                      <option
                                        selected={
                                          operatingSundayFrom === "16"
                                            ? true
                                            : false
                                        }
                                        value="16"
                                      >
                                        16:00
                                      </option>
                                      <option
                                        selected={
                                          operatingSundayFrom === "17"
                                            ? true
                                            : false
                                        }
                                        value="17"
                                      >
                                        17:00
                                      </option>
                                      <option
                                        selected={
                                          operatingSundayFrom === "18"
                                            ? true
                                            : false
                                        }
                                        value="18"
                                      >
                                        18:00
                                      </option>
                                      <option
                                        selected={
                                          operatingSundayFrom === "19"
                                            ? true
                                            : false
                                        }
                                        value="19"
                                      >
                                        19:00
                                      </option>
                                      <option
                                        selected={
                                          operatingSundayFrom === "20"
                                            ? true
                                            : false
                                        }
                                        value="20"
                                      >
                                        20:00
                                      </option>
                                    </Form.Select>
                                  </Col>
                                  <Col
                                    lg="5"
                                    md=""
                                    sm=""
                                    style={{ paddingLeft: "10px" }}
                                  >
                                    <Form.Select
                                      aria-label="Default select example"
                                      style={{ borderRadius: "12px" }}
                                      disabled
                                    >
                                      <option value="0">To</option>
                                      <option
                                        selected={
                                          operatingSundayTo === "6"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingSundayFrom) >= 6
                                            ? true
                                            : false
                                        }
                                        value="6"
                                      >
                                        6:00
                                      </option>
                                      <option
                                        selected={
                                          operatingSundayTo === "7"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingSundayFrom) >= 7
                                            ? true
                                            : false
                                        }
                                        value="7"
                                      >
                                        7:00
                                      </option>
                                      <option
                                        selected={
                                          operatingSundayTo === "8"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingSundayFrom) >= 8
                                            ? true
                                            : false
                                        }
                                        value="8"
                                      >
                                        8:00
                                      </option>
                                      <option
                                        selected={
                                          operatingSundayTo === "9"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingSundayFrom) >= 9
                                            ? true
                                            : false
                                        }
                                        value="9"
                                      >
                                        9:00
                                      </option>
                                      <option
                                        selected={
                                          operatingSundayTo === "10"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingSundayFrom) >= 10
                                            ? true
                                            : false
                                        }
                                        value="10"
                                      >
                                        10:00
                                      </option>
                                      <option
                                        selected={
                                          operatingSundayTo === "11"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingSundayFrom) >= 11
                                            ? true
                                            : false
                                        }
                                        value="11"
                                      >
                                        11:00
                                      </option>
                                      <option
                                        selected={
                                          operatingSundayTo === "12"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingSundayFrom) >= 12
                                            ? true
                                            : false
                                        }
                                        value="12"
                                      >
                                        12:00
                                      </option>
                                      <option
                                        selected={
                                          operatingSundayTo === "13"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingSundayFrom) >= 13
                                            ? true
                                            : false
                                        }
                                        value="13"
                                      >
                                        13:00
                                      </option>
                                      <option
                                        selected={
                                          operatingSundayTo === "14"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingSundayFrom) >= 14
                                            ? true
                                            : false
                                        }
                                        value="14"
                                      >
                                        14:00
                                      </option>
                                      <option
                                        selected={
                                          operatingSundayTo === "15"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingSundayFrom) >= 15
                                            ? true
                                            : false
                                        }
                                        value="15"
                                      >
                                        15:00
                                      </option>
                                      <option
                                        selected={
                                          operatingSundayTo === "16"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingSundayFrom) >= 16
                                            ? true
                                            : false
                                        }
                                        value="16"
                                      >
                                        16:00
                                      </option>
                                      <option
                                        selected={
                                          operatingSundayTo === "17"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingSundayFrom) >= 17
                                            ? true
                                            : false
                                        }
                                        value="17"
                                      >
                                        17:00
                                      </option>
                                      <option
                                        selected={
                                          operatingSundayTo === "18"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingSundayFrom) >= 18
                                            ? true
                                            : false
                                        }
                                        value="18"
                                      >
                                        18:00
                                      </option>
                                      <option
                                        selected={
                                          operatingSundayTo === "19"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingSundayFrom) >= 19
                                            ? true
                                            : false
                                        }
                                        value="19"
                                      >
                                        19:00
                                      </option>
                                      <option
                                        selected={
                                          operatingSundayTo === "20"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingSundayFrom) >= 20
                                            ? true
                                            : false
                                        }
                                        value="20"
                                      >
                                        20:00
                                      </option>
                                    </Form.Select>
                                  </Col>
                                </Col>
                                {/* Monday */}
                                <Col
                                  md="12"
                                  style={{
                                    color: "#A6A6A6",
                                    paddingRight: "10px",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Col lg="2" md="" sm="">
                                    {["checkbox"].map((type) => (
                                      <div
                                        key={`inline-${type}`}
                                        className="mb-3"
                                      >
                                        <Col
                                          lg="12"
                                          md="12"
                                          sm="12"
                                          xs="12"
                                          style={{ marginTop: 10 }}
                                        >
                                          <Form.Check
                                            inline
                                            label="Mon"
                                            name="Monday"
                                            type={type}
                                            id={`inline-${type}-2`}
                                            style={{ color: netural400 }}
                                            checked={
                                              isSelectedMonday ? true : false
                                            }
                                            disabled
                                          />
                                        </Col>
                                      </div>
                                    ))}
                                  </Col>
                                  <Col
                                    lg="5"
                                    md=""
                                    sm=""
                                    style={{ paddingRight: "10px" }}
                                  >
                                    <Form.Select
                                      aria-label="Default select example"
                                      style={{ borderRadius: "12px" }}
                                      disabled
                                    >
                                      <option value="0">From</option>
                                      <option
                                        selected={
                                          operatingMondayFrom === "6"
                                            ? true
                                            : false
                                        }
                                        value="6"
                                      >
                                        6:00
                                      </option>
                                      <option
                                        selected={
                                          operatingMondayFrom === "7"
                                            ? true
                                            : false
                                        }
                                        value="7"
                                      >
                                        7:00
                                      </option>
                                      <option
                                        selected={
                                          operatingMondayFrom === "8"
                                            ? true
                                            : false
                                        }
                                        value="8"
                                      >
                                        8:00
                                      </option>
                                      <option
                                        selected={
                                          operatingMondayFrom === "9"
                                            ? true
                                            : false
                                        }
                                        value="9"
                                      >
                                        9:00
                                      </option>
                                      <option
                                        selected={
                                          operatingMondayFrom === "10"
                                            ? true
                                            : false
                                        }
                                        value="10"
                                      >
                                        10:00
                                      </option>
                                      <option
                                        selected={
                                          operatingMondayFrom === "11"
                                            ? true
                                            : false
                                        }
                                        value="11"
                                      >
                                        11:00
                                      </option>
                                      <option
                                        selected={
                                          operatingMondayFrom === "12"
                                            ? true
                                            : false
                                        }
                                        value="12"
                                      >
                                        12:00
                                      </option>
                                      <option
                                        selected={
                                          operatingMondayFrom === "13"
                                            ? true
                                            : false
                                        }
                                        value="13"
                                      >
                                        13:00
                                      </option>
                                      <option
                                        selected={
                                          operatingMondayFrom === "14"
                                            ? true
                                            : false
                                        }
                                        value="14"
                                      >
                                        14:00
                                      </option>
                                      <option
                                        selected={
                                          operatingMondayFrom === "15"
                                            ? true
                                            : false
                                        }
                                        value="15"
                                      >
                                        15:00
                                      </option>
                                      <option
                                        selected={
                                          operatingMondayFrom === "16"
                                            ? true
                                            : false
                                        }
                                        value="16"
                                      >
                                        16:00
                                      </option>
                                      <option
                                        selected={
                                          operatingMondayFrom === "17"
                                            ? true
                                            : false
                                        }
                                        value="17"
                                      >
                                        17:00
                                      </option>
                                      <option
                                        selected={
                                          operatingMondayFrom === "18"
                                            ? true
                                            : false
                                        }
                                        value="18"
                                      >
                                        18:00
                                      </option>
                                      <option
                                        selected={
                                          operatingMondayFrom === "19"
                                            ? true
                                            : false
                                        }
                                        value="19"
                                      >
                                        19:00
                                      </option>
                                      <option
                                        selected={
                                          operatingMondayFrom === "20"
                                            ? true
                                            : false
                                        }
                                        value="20"
                                      >
                                        20:00
                                      </option>
                                    </Form.Select>
                                  </Col>
                                  <Col
                                    lg="5"
                                    md=""
                                    sm=""
                                    style={{ paddingLeft: "10px" }}
                                  >
                                    <Form.Select
                                      aria-label="Default select example"
                                      style={{ borderRadius: "12px" }}
                                      disabled
                                    >
                                      <option value="0">To</option>
                                      <option
                                        selected={
                                          operatingMondayTo === "6"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingMondayFrom) >= 6
                                            ? true
                                            : false
                                        }
                                        value="6"
                                      >
                                        6:00
                                      </option>
                                      <option
                                        selected={
                                          operatingMondayTo === "7"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingMondayFrom) >= 7
                                            ? true
                                            : false
                                        }
                                        value="7"
                                      >
                                        7:00
                                      </option>
                                      <option
                                        selected={
                                          operatingMondayTo === "8"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingMondayFrom) >= 8
                                            ? true
                                            : false
                                        }
                                        value="8"
                                      >
                                        8:00
                                      </option>
                                      <option
                                        selected={
                                          operatingMondayTo === "9"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingMondayFrom) >= 9
                                            ? true
                                            : false
                                        }
                                        value="9"
                                      >
                                        9:00
                                      </option>
                                      <option
                                        selected={
                                          operatingMondayTo === "10"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingMondayFrom) >= 10
                                            ? true
                                            : false
                                        }
                                        value="10"
                                      >
                                        10:00
                                      </option>
                                      <option
                                        selected={
                                          operatingMondayTo === "11"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingMondayFrom) >= 11
                                            ? true
                                            : false
                                        }
                                        value="11"
                                      >
                                        11:00
                                      </option>
                                      <option
                                        selected={
                                          operatingMondayTo === "12"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingMondayFrom) >= 12
                                            ? true
                                            : false
                                        }
                                        value="12"
                                      >
                                        12:00
                                      </option>
                                      <option
                                        selected={
                                          operatingMondayTo === "13"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingMondayFrom) >= 13
                                            ? true
                                            : false
                                        }
                                        value="13"
                                      >
                                        13:00
                                      </option>
                                      <option
                                        selected={
                                          operatingMondayTo === "14"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingMondayFrom) >= 14
                                            ? true
                                            : false
                                        }
                                        value="14"
                                      >
                                        14:00
                                      </option>
                                      <option
                                        selected={
                                          operatingMondayTo === "15"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingMondayFrom) >= 15
                                            ? true
                                            : false
                                        }
                                        value="15"
                                      >
                                        15:00
                                      </option>
                                      <option
                                        selected={
                                          operatingMondayTo === "16"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingMondayFrom) >= 16
                                            ? true
                                            : false
                                        }
                                        value="16"
                                      >
                                        16:00
                                      </option>
                                      <option
                                        selected={
                                          operatingMondayTo === "17"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingMondayFrom) >= 17
                                            ? true
                                            : false
                                        }
                                        value="17"
                                      >
                                        17:00
                                      </option>
                                      <option
                                        selected={
                                          operatingMondayTo === "18"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingMondayFrom) >= 18
                                            ? true
                                            : false
                                        }
                                        value="18"
                                      >
                                        18:00
                                      </option>
                                      <option
                                        selected={
                                          operatingMondayTo === "19"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingMondayFrom) >= 19
                                            ? true
                                            : false
                                        }
                                        value="19"
                                      >
                                        19:00
                                      </option>
                                      <option
                                        selected={
                                          operatingMondayTo === "20"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingMondayFrom) >= 20
                                            ? true
                                            : false
                                        }
                                        value="20"
                                      >
                                        20:00
                                      </option>
                                    </Form.Select>
                                  </Col>
                                </Col>
                                {/* Tuesday */}
                                <Col
                                  md="12"
                                  style={{
                                    color: "#A6A6A6",
                                    paddingRight: "10px",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Col lg="2" md="" sm="">
                                    {["checkbox"].map((type) => (
                                      <div
                                        key={`inline-${type}`}
                                        className="mb-3"
                                      >
                                        <Col
                                          lg="12"
                                          md="12"
                                          sm="12"
                                          xs="12"
                                          style={{ marginTop: 10 }}
                                        >
                                          <Form.Check
                                            inline
                                            label="Tue"
                                            name="Tuesday"
                                            type={type}
                                            id={`inline-${type}-2`}
                                            style={{ color: netural400 }}
                                            checked={
                                              isSelectedTuesday ? true : false
                                            }
                                            disabled
                                          />
                                        </Col>
                                      </div>
                                    ))}
                                  </Col>
                                  <Col
                                    lg="5"
                                    md=""
                                    sm=""
                                    style={{ paddingRight: "10px" }}
                                  >
                                    <Form.Select
                                      aria-label="Default select example"
                                      style={{ borderRadius: "12px" }}
                                      disabled
                                    >
                                      <option value="0">From</option>
                                      <option
                                        selected={
                                          operatingTuesdayFrom === "6"
                                            ? true
                                            : false
                                        }
                                        value="6"
                                      >
                                        6:00
                                      </option>
                                      <option
                                        selected={
                                          operatingTuesdayFrom === "7"
                                            ? true
                                            : false
                                        }
                                        value="7"
                                      >
                                        7:00
                                      </option>
                                      <option
                                        selected={
                                          operatingTuesdayFrom === "8"
                                            ? true
                                            : false
                                        }
                                        value="8"
                                      >
                                        8:00
                                      </option>
                                      <option
                                        selected={
                                          operatingTuesdayFrom === "9"
                                            ? true
                                            : false
                                        }
                                        value="9"
                                      >
                                        9:00
                                      </option>
                                      <option
                                        selected={
                                          operatingTuesdayFrom === "10"
                                            ? true
                                            : false
                                        }
                                        value="10"
                                      >
                                        10:00
                                      </option>
                                      <option
                                        selected={
                                          operatingTuesdayFrom === "11"
                                            ? true
                                            : false
                                        }
                                        value="11"
                                      >
                                        11:00
                                      </option>
                                      <option
                                        selected={
                                          operatingTuesdayFrom === "12"
                                            ? true
                                            : false
                                        }
                                        value="12"
                                      >
                                        12:00
                                      </option>
                                      <option
                                        selected={
                                          operatingTuesdayFrom === "13"
                                            ? true
                                            : false
                                        }
                                        value="13"
                                      >
                                        13:00
                                      </option>
                                      <option
                                        selected={
                                          operatingTuesdayFrom === "14"
                                            ? true
                                            : false
                                        }
                                        value="14"
                                      >
                                        14:00
                                      </option>
                                      <option
                                        selected={
                                          operatingTuesdayFrom === "15"
                                            ? true
                                            : false
                                        }
                                        value="15"
                                      >
                                        15:00
                                      </option>
                                      <option
                                        selected={
                                          operatingTuesdayFrom === "16"
                                            ? true
                                            : false
                                        }
                                        value="16"
                                      >
                                        16:00
                                      </option>
                                      <option
                                        selected={
                                          operatingTuesdayFrom === "17"
                                            ? true
                                            : false
                                        }
                                        value="17"
                                      >
                                        17:00
                                      </option>
                                      <option
                                        selected={
                                          operatingTuesdayFrom === "18"
                                            ? true
                                            : false
                                        }
                                        value="18"
                                      >
                                        18:00
                                      </option>
                                      <option
                                        selected={
                                          operatingTuesdayFrom === "19"
                                            ? true
                                            : false
                                        }
                                        value="19"
                                      >
                                        19:00
                                      </option>
                                      <option
                                        selected={
                                          operatingTuesdayFrom === "20"
                                            ? true
                                            : false
                                        }
                                        value="20"
                                      >
                                        20:00
                                      </option>
                                    </Form.Select>
                                  </Col>
                                  <Col
                                    lg="5"
                                    md=""
                                    sm=""
                                    style={{ paddingLeft: "10px" }}
                                  >
                                    <Form.Select
                                      aria-label="Default select example"
                                      style={{ borderRadius: "12px" }}
                                      disabled
                                    >
                                      <option value="0">To</option>
                                      <option
                                        selected={
                                          operatingTuesdayTo === "6"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingTuesdayFrom) >= 6
                                            ? true
                                            : false
                                        }
                                        value="6"
                                      >
                                        6:00
                                      </option>
                                      <option
                                        selected={
                                          operatingTuesdayTo === "7"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingTuesdayFrom) >= 7
                                            ? true
                                            : false
                                        }
                                        value="7"
                                      >
                                        7:00
                                      </option>
                                      <option
                                        selected={
                                          operatingTuesdayTo === "8"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingTuesdayFrom) >= 8
                                            ? true
                                            : false
                                        }
                                        value="8"
                                      >
                                        8:00
                                      </option>
                                      <option
                                        selected={
                                          operatingTuesdayTo === "9"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingTuesdayFrom) >= 9
                                            ? true
                                            : false
                                        }
                                        value="9"
                                      >
                                        9:00
                                      </option>
                                      <option
                                        selected={
                                          operatingTuesdayTo === "10"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingTuesdayFrom) >= 10
                                            ? true
                                            : false
                                        }
                                        value="10"
                                      >
                                        10:00
                                      </option>
                                      <option
                                        selected={
                                          operatingTuesdayTo === "11"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingTuesdayFrom) >= 11
                                            ? true
                                            : false
                                        }
                                        value="11"
                                      >
                                        11:00
                                      </option>
                                      <option
                                        selected={
                                          operatingTuesdayTo === "12"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingTuesdayFrom) >= 12
                                            ? true
                                            : false
                                        }
                                        value="12"
                                      >
                                        12:00
                                      </option>
                                      <option
                                        selected={
                                          operatingTuesdayTo === "13"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingTuesdayFrom) >= 13
                                            ? true
                                            : false
                                        }
                                        value="13"
                                      >
                                        13:00
                                      </option>
                                      <option
                                        selected={
                                          operatingTuesdayTo === "14"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingTuesdayFrom) >= 14
                                            ? true
                                            : false
                                        }
                                        value="14"
                                      >
                                        14:00
                                      </option>
                                      <option
                                        selected={
                                          operatingTuesdayTo === "15"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingTuesdayFrom) >= 15
                                            ? true
                                            : false
                                        }
                                        value="15"
                                      >
                                        15:00
                                      </option>
                                      <option
                                        selected={
                                          operatingTuesdayTo === "16"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingTuesdayFrom) >= 16
                                            ? true
                                            : false
                                        }
                                        value="16"
                                      >
                                        16:00
                                      </option>
                                      <option
                                        selected={
                                          operatingTuesdayTo === "17"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingTuesdayFrom) >= 17
                                            ? true
                                            : false
                                        }
                                        value="17"
                                      >
                                        17:00
                                      </option>
                                      <option
                                        selected={
                                          operatingTuesdayTo === "18"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingTuesdayFrom) >= 18
                                            ? true
                                            : false
                                        }
                                        value="18"
                                      >
                                        18:00
                                      </option>
                                      <option
                                        selected={
                                          operatingTuesdayTo === "19"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingTuesdayFrom) >= 19
                                            ? true
                                            : false
                                        }
                                        value="19"
                                      >
                                        19:00
                                      </option>
                                      <option
                                        selected={
                                          operatingTuesdayTo === "20"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingTuesdayFrom) >= 20
                                            ? true
                                            : false
                                        }
                                        value="20"
                                      >
                                        20:00
                                      </option>
                                    </Form.Select>
                                  </Col>
                                </Col>
                                {/* Wednesday */}
                                <Col
                                  md="12"
                                  style={{
                                    color: "#A6A6A6",
                                    paddingRight: "10px",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Col lg="2" md="" sm="">
                                    {["checkbox"].map((type) => (
                                      <div
                                        key={`inline-${type}`}
                                        className="mb-3"
                                      >
                                        <Col
                                          lg="12"
                                          md="12"
                                          sm="12"
                                          xs="12"
                                          style={{ marginTop: 10 }}
                                        >
                                          <Form.Check
                                            inline
                                            label="Wed"
                                            name="Wednesday"
                                            type={type}
                                            id={`inline-${type}-2`}
                                            style={{ color: netural400 }}
                                            checked={
                                              isSelectedWednesday ? true : false
                                            }
                                            disabled
                                          />
                                        </Col>
                                      </div>
                                    ))}
                                  </Col>
                                  <Col
                                    lg="5"
                                    md=""
                                    sm=""
                                    style={{ paddingRight: "10px" }}
                                  >
                                    <Form.Select
                                      aria-label="Default select example"
                                      style={{ borderRadius: "12px" }}
                                      disabled
                                    >
                                      <option value="0">From</option>
                                      <option
                                        selected={
                                          operatingWednesdayFrom === "6"
                                            ? true
                                            : false
                                        }
                                        value="6"
                                      >
                                        6:00
                                      </option>
                                      <option
                                        selected={
                                          operatingWednesdayFrom === "7"
                                            ? true
                                            : false
                                        }
                                        value="7"
                                      >
                                        7:00
                                      </option>
                                      <option
                                        selected={
                                          operatingWednesdayFrom === "8"
                                            ? true
                                            : false
                                        }
                                        value="8"
                                      >
                                        8:00
                                      </option>
                                      <option
                                        selected={
                                          operatingWednesdayFrom === "9"
                                            ? true
                                            : false
                                        }
                                        value="9"
                                      >
                                        9:00
                                      </option>
                                      <option
                                        selected={
                                          operatingWednesdayFrom === "10"
                                            ? true
                                            : false
                                        }
                                        value="10"
                                      >
                                        10:00
                                      </option>
                                      <option
                                        selected={
                                          operatingWednesdayFrom === "11"
                                            ? true
                                            : false
                                        }
                                        value="11"
                                      >
                                        11:00
                                      </option>
                                      <option
                                        selected={
                                          operatingWednesdayFrom === "12"
                                            ? true
                                            : false
                                        }
                                        value="12"
                                      >
                                        12:00
                                      </option>
                                      <option
                                        selected={
                                          operatingWednesdayFrom === "13"
                                            ? true
                                            : false
                                        }
                                        value="13"
                                      >
                                        13:00
                                      </option>
                                      <option
                                        selected={
                                          operatingWednesdayFrom === "14"
                                            ? true
                                            : false
                                        }
                                        value="14"
                                      >
                                        14:00
                                      </option>
                                      <option
                                        selected={
                                          operatingWednesdayFrom === "15"
                                            ? true
                                            : false
                                        }
                                        value="15"
                                      >
                                        15:00
                                      </option>
                                      <option
                                        selected={
                                          operatingWednesdayFrom === "16"
                                            ? true
                                            : false
                                        }
                                        value="16"
                                      >
                                        16:00
                                      </option>
                                      <option
                                        selected={
                                          operatingWednesdayFrom === "17"
                                            ? true
                                            : false
                                        }
                                        value="17"
                                      >
                                        17:00
                                      </option>
                                      <option
                                        selected={
                                          operatingWednesdayFrom === "18"
                                            ? true
                                            : false
                                        }
                                        value="18"
                                      >
                                        18:00
                                      </option>
                                      <option
                                        selected={
                                          operatingWednesdayFrom === "19"
                                            ? true
                                            : false
                                        }
                                        value="19"
                                      >
                                        19:00
                                      </option>
                                      <option
                                        selected={
                                          operatingWednesdayFrom === "20"
                                            ? true
                                            : false
                                        }
                                        value="20"
                                      >
                                        20:00
                                      </option>
                                    </Form.Select>
                                  </Col>
                                  <Col
                                    lg="5"
                                    md=""
                                    sm=""
                                    style={{ paddingLeft: "10px" }}
                                  >
                                    <Form.Select
                                      aria-label="Default select example"
                                      style={{ borderRadius: "12px" }}
                                      disabled
                                    >
                                      <option value="0">To</option>
                                      <option
                                        selected={
                                          operatingWednesdayTo === "6"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingWednesdayFrom) >= 6
                                            ? true
                                            : false
                                        }
                                        value="6"
                                      >
                                        6:00
                                      </option>
                                      <option
                                        selected={
                                          operatingWednesdayTo === "7"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingWednesdayFrom) >= 7
                                            ? true
                                            : false
                                        }
                                        value="7"
                                      >
                                        7:00
                                      </option>
                                      <option
                                        selected={
                                          operatingWednesdayTo === "8"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingWednesdayFrom) >= 8
                                            ? true
                                            : false
                                        }
                                        value="8"
                                      >
                                        8:00
                                      </option>
                                      <option
                                        selected={
                                          operatingWednesdayTo === "9"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingWednesdayFrom) >= 9
                                            ? true
                                            : false
                                        }
                                        value="9"
                                      >
                                        9:00
                                      </option>
                                      <option
                                        selected={
                                          operatingWednesdayTo === "10"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingWednesdayFrom) >= 10
                                            ? true
                                            : false
                                        }
                                        value="10"
                                      >
                                        10:00
                                      </option>
                                      <option
                                        selected={
                                          operatingWednesdayTo === "11"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingWednesdayFrom) >= 11
                                            ? true
                                            : false
                                        }
                                        value="11"
                                      >
                                        11:00
                                      </option>
                                      <option
                                        selected={
                                          operatingWednesdayTo === "12"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingWednesdayFrom) >= 12
                                            ? true
                                            : false
                                        }
                                        value="12"
                                      >
                                        12:00
                                      </option>
                                      <option
                                        selected={
                                          operatingWednesdayTo === "13"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingWednesdayFrom) >= 13
                                            ? true
                                            : false
                                        }
                                        value="13"
                                      >
                                        13:00
                                      </option>
                                      <option
                                        selected={
                                          operatingWednesdayTo === "14"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingWednesdayFrom) >= 14
                                            ? true
                                            : false
                                        }
                                        value="14"
                                      >
                                        14:00
                                      </option>
                                      <option
                                        selected={
                                          operatingWednesdayTo === "15"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingWednesdayFrom) >= 15
                                            ? true
                                            : false
                                        }
                                        value="15"
                                      >
                                        15:00
                                      </option>
                                      <option
                                        selected={
                                          operatingWednesdayTo === "16"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingWednesdayFrom) >= 16
                                            ? true
                                            : false
                                        }
                                        value="16"
                                      >
                                        16:00
                                      </option>
                                      <option
                                        selected={
                                          operatingWednesdayTo === "17"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingWednesdayFrom) >= 17
                                            ? true
                                            : false
                                        }
                                        value="17"
                                      >
                                        17:00
                                      </option>
                                      <option
                                        selected={
                                          operatingWednesdayTo === "18"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingWednesdayFrom) >= 18
                                            ? true
                                            : false
                                        }
                                        value="18"
                                      >
                                        18:00
                                      </option>
                                      <option
                                        selected={
                                          operatingWednesdayTo === "19"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingWednesdayFrom) >= 19
                                            ? true
                                            : false
                                        }
                                        value="19"
                                      >
                                        19:00
                                      </option>
                                      <option
                                        selected={
                                          operatingWednesdayTo === "20"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingWednesdayFrom) >= 20
                                            ? true
                                            : false
                                        }
                                        value="20"
                                      >
                                        20:00
                                      </option>
                                    </Form.Select>
                                  </Col>
                                </Col>
                                {/* Thursday */}
                                <Col
                                  md="12"
                                  style={{
                                    color: "#A6A6A6",
                                    paddingRight: "10px",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Col lg="2" md="" sm="">
                                    {["checkbox"].map((type) => (
                                      <div
                                        key={`inline-${type}`}
                                        className="mb-3"
                                      >
                                        <Col
                                          lg="12"
                                          md="12"
                                          sm="12"
                                          xs="12"
                                          style={{ marginTop: 10 }}
                                        >
                                          <Form.Check
                                            inline
                                            label="Thu"
                                            name="Thursday"
                                            type={type}
                                            id={`inline-${type}-2`}
                                            style={{ color: netural400 }}
                                            checked={
                                              isSelectedThursday ? true : false
                                            }
                                            disabled
                                          />
                                        </Col>
                                      </div>
                                    ))}
                                  </Col>
                                  <Col
                                    lg="5"
                                    md=""
                                    sm=""
                                    style={{ paddingRight: "10px" }}
                                  >
                                    <Form.Select
                                      aria-label="Default select example"
                                      style={{ borderRadius: "12px" }}
                                      disabled
                                    >
                                      <option value="0">From</option>
                                      <option
                                        selected={
                                          operatingThursdayFrom === "6"
                                            ? true
                                            : false
                                        }
                                        value="6"
                                      >
                                        6:00
                                      </option>
                                      <option
                                        selected={
                                          operatingThursdayFrom === "7"
                                            ? true
                                            : false
                                        }
                                        value="7"
                                      >
                                        7:00
                                      </option>
                                      <option
                                        selected={
                                          operatingThursdayFrom === "8"
                                            ? true
                                            : false
                                        }
                                        value="8"
                                      >
                                        8:00
                                      </option>
                                      <option
                                        selected={
                                          operatingThursdayFrom === "9"
                                            ? true
                                            : false
                                        }
                                        value="9"
                                      >
                                        9:00
                                      </option>
                                      <option
                                        selected={
                                          operatingThursdayFrom === "10"
                                            ? true
                                            : false
                                        }
                                        value="10"
                                      >
                                        10:00
                                      </option>
                                      <option
                                        selected={
                                          operatingThursdayFrom === "11"
                                            ? true
                                            : false
                                        }
                                        value="11"
                                      >
                                        11:00
                                      </option>
                                      <option
                                        selected={
                                          operatingThursdayFrom === "12"
                                            ? true
                                            : false
                                        }
                                        value="12"
                                      >
                                        12:00
                                      </option>
                                      <option
                                        selected={
                                          operatingThursdayFrom === "13"
                                            ? true
                                            : false
                                        }
                                        value="13"
                                      >
                                        13:00
                                      </option>
                                      <option
                                        selected={
                                          operatingThursdayFrom === "14"
                                            ? true
                                            : false
                                        }
                                        value="14"
                                      >
                                        14:00
                                      </option>
                                      <option
                                        selected={
                                          operatingThursdayFrom === "15"
                                            ? true
                                            : false
                                        }
                                        value="15"
                                      >
                                        15:00
                                      </option>
                                      <option
                                        selected={
                                          operatingThursdayFrom === "16"
                                            ? true
                                            : false
                                        }
                                        value="16"
                                      >
                                        16:00
                                      </option>
                                      <option
                                        selected={
                                          operatingThursdayFrom === "17"
                                            ? true
                                            : false
                                        }
                                        value="17"
                                      >
                                        17:00
                                      </option>
                                      <option
                                        selected={
                                          operatingThursdayFrom === "18"
                                            ? true
                                            : false
                                        }
                                        value="18"
                                      >
                                        18:00
                                      </option>
                                      <option
                                        selected={
                                          operatingThursdayFrom === "19"
                                            ? true
                                            : false
                                        }
                                        value="19"
                                      >
                                        19:00
                                      </option>
                                      <option
                                        selected={
                                          operatingThursdayFrom === "20"
                                            ? true
                                            : false
                                        }
                                        value="20"
                                      >
                                        20:00
                                      </option>
                                    </Form.Select>
                                  </Col>
                                  <Col
                                    lg="5"
                                    md=""
                                    sm=""
                                    style={{ paddingLeft: "10px" }}
                                  >
                                    <Form.Select
                                      aria-label="Default select example"
                                      style={{ borderRadius: "12px" }}
                                      disabled
                                    >
                                      <option value="0">To</option>
                                      <option
                                        selected={
                                          operatingThursdayTo === "6"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingThursdayFrom) >= 6
                                            ? true
                                            : false
                                        }
                                        value="6"
                                      >
                                        6:00
                                      </option>
                                      <option
                                        selected={
                                          operatingThursdayTo === "7"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingThursdayFrom) >= 7
                                            ? true
                                            : false
                                        }
                                        value="7"
                                      >
                                        7:00
                                      </option>
                                      <option
                                        selected={
                                          operatingThursdayTo === "8"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingThursdayFrom) >= 8
                                            ? true
                                            : false
                                        }
                                        value="8"
                                      >
                                        8:00
                                      </option>
                                      <option
                                        selected={
                                          operatingThursdayTo === "9"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingThursdayFrom) >= 9
                                            ? true
                                            : false
                                        }
                                        value="9"
                                      >
                                        9:00
                                      </option>
                                      <option
                                        selected={
                                          operatingThursdayTo === "10"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingThursdayFrom) >= 10
                                            ? true
                                            : false
                                        }
                                        value="10"
                                      >
                                        10:00
                                      </option>
                                      <option
                                        selected={
                                          operatingThursdayTo === "11"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingThursdayFrom) >= 11
                                            ? true
                                            : false
                                        }
                                        value="11"
                                      >
                                        11:00
                                      </option>
                                      <option
                                        selected={
                                          operatingThursdayTo === "12"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingThursdayFrom) >= 12
                                            ? true
                                            : false
                                        }
                                        value="12"
                                      >
                                        12:00
                                      </option>
                                      <option
                                        selected={
                                          operatingThursdayTo === "13"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingThursdayFrom) >= 13
                                            ? true
                                            : false
                                        }
                                        value="13"
                                      >
                                        13:00
                                      </option>
                                      <option
                                        selected={
                                          operatingThursdayTo === "14"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingThursdayFrom) >= 14
                                            ? true
                                            : false
                                        }
                                        value="14"
                                      >
                                        14:00
                                      </option>
                                      <option
                                        selected={
                                          operatingThursdayTo === "15"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingThursdayFrom) >= 15
                                            ? true
                                            : false
                                        }
                                        value="15"
                                      >
                                        15:00
                                      </option>
                                      <option
                                        selected={
                                          operatingThursdayTo === "16"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingThursdayFrom) >= 16
                                            ? true
                                            : false
                                        }
                                        value="16"
                                      >
                                        16:00
                                      </option>
                                      <option
                                        selected={
                                          operatingThursdayTo === "17"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingThursdayFrom) >= 17
                                            ? true
                                            : false
                                        }
                                        value="17"
                                      >
                                        17:00
                                      </option>
                                      <option
                                        selected={
                                          operatingThursdayTo === "18"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingThursdayFrom) >= 18
                                            ? true
                                            : false
                                        }
                                        value="18"
                                      >
                                        18:00
                                      </option>
                                      <option
                                        selected={
                                          operatingThursdayTo === "19"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingThursdayFrom) >= 19
                                            ? true
                                            : false
                                        }
                                        value="19"
                                      >
                                        19:00
                                      </option>
                                      <option
                                        selected={
                                          operatingThursdayTo === "20"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingThursdayFrom) >= 20
                                            ? true
                                            : false
                                        }
                                        value="20"
                                      >
                                        20:00
                                      </option>
                                    </Form.Select>
                                  </Col>
                                </Col>
                                {/* Friday */}
                                <Col
                                  md="12"
                                  style={{
                                    color: "#A6A6A6",
                                    paddingRight: "10px",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Col lg="2" md="" sm="">
                                    {["checkbox"].map((type) => (
                                      <div
                                        key={`inline-${type}`}
                                        className="mb-3"
                                      >
                                        <Col
                                          lg="12"
                                          md="12"
                                          sm="12"
                                          xs="12"
                                          style={{ marginTop: 10 }}
                                        >
                                          <Form.Check
                                            inline
                                            label="Fri"
                                            name="Friday"
                                            type={type}
                                            id={`inline-${type}-2`}
                                            style={{ color: netural400 }}
                                            checked={
                                              isSelectedFriday ? true : false
                                            }
                                            disabled
                                          />
                                        </Col>
                                      </div>
                                    ))}
                                  </Col>
                                  <Col
                                    lg="5"
                                    md=""
                                    sm=""
                                    style={{ paddingRight: "10px" }}
                                  >
                                    <Form.Select
                                      aria-label="Default select example"
                                      style={{ borderRadius: "12px" }}
                                      disabled
                                    >
                                      <option value="0">From</option>
                                      <option
                                        selected={
                                          operatingFridayFrom === "6"
                                            ? true
                                            : false
                                        }
                                        value="6"
                                      >
                                        6:00
                                      </option>
                                      <option
                                        selected={
                                          operatingFridayFrom === "7"
                                            ? true
                                            : false
                                        }
                                        value="7"
                                      >
                                        7:00
                                      </option>
                                      <option
                                        selected={
                                          operatingFridayFrom === "8"
                                            ? true
                                            : false
                                        }
                                        value="8"
                                      >
                                        8:00
                                      </option>
                                      <option
                                        selected={
                                          operatingFridayFrom === "9"
                                            ? true
                                            : false
                                        }
                                        value="9"
                                      >
                                        9:00
                                      </option>
                                      <option
                                        selected={
                                          operatingFridayFrom === "10"
                                            ? true
                                            : false
                                        }
                                        value="10"
                                      >
                                        10:00
                                      </option>
                                      <option
                                        selected={
                                          operatingFridayFrom === "11"
                                            ? true
                                            : false
                                        }
                                        value="11"
                                      >
                                        11:00
                                      </option>
                                      <option
                                        selected={
                                          operatingFridayFrom === "12"
                                            ? true
                                            : false
                                        }
                                        value="12"
                                      >
                                        12:00
                                      </option>
                                      <option
                                        selected={
                                          operatingFridayFrom === "13"
                                            ? true
                                            : false
                                        }
                                        value="13"
                                      >
                                        13:00
                                      </option>
                                      <option
                                        selected={
                                          operatingFridayFrom === "14"
                                            ? true
                                            : false
                                        }
                                        value="14"
                                      >
                                        14:00
                                      </option>
                                      <option
                                        selected={
                                          operatingFridayFrom === "15"
                                            ? true
                                            : false
                                        }
                                        value="15"
                                      >
                                        15:00
                                      </option>
                                      <option
                                        selected={
                                          operatingFridayFrom === "16"
                                            ? true
                                            : false
                                        }
                                        value="16"
                                      >
                                        16:00
                                      </option>
                                      <option
                                        selected={
                                          operatingFridayFrom === "17"
                                            ? true
                                            : false
                                        }
                                        value="17"
                                      >
                                        17:00
                                      </option>
                                      <option
                                        selected={
                                          operatingFridayFrom === "18"
                                            ? true
                                            : false
                                        }
                                        value="18"
                                      >
                                        18:00
                                      </option>
                                      <option
                                        selected={
                                          operatingFridayFrom === "19"
                                            ? true
                                            : false
                                        }
                                        value="19"
                                      >
                                        19:00
                                      </option>
                                      <option
                                        selected={
                                          operatingFridayFrom === "20"
                                            ? true
                                            : false
                                        }
                                        value="20"
                                      >
                                        20:00
                                      </option>
                                    </Form.Select>
                                  </Col>
                                  <Col
                                    lg="5"
                                    md=""
                                    sm=""
                                    style={{ paddingLeft: "10px" }}
                                  >
                                    <Form.Select
                                      aria-label="Default select example"
                                      style={{ borderRadius: "12px" }}
                                      disabled
                                    >
                                      <option value="0">To</option>
                                      <option
                                        selected={
                                          operatingFridayTo === "6"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingFridayFrom) >= 6
                                            ? true
                                            : false
                                        }
                                        value="6"
                                      >
                                        6:00
                                      </option>
                                      <option
                                        selected={
                                          operatingFridayTo === "7"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingFridayFrom) >= 7
                                            ? true
                                            : false
                                        }
                                        value="7"
                                      >
                                        7:00
                                      </option>
                                      <option
                                        selected={
                                          operatingFridayTo === "8"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingFridayFrom) >= 8
                                            ? true
                                            : false
                                        }
                                        value="8"
                                      >
                                        8:00
                                      </option>
                                      <option
                                        selected={
                                          operatingFridayTo === "9"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingFridayFrom) >= 9
                                            ? true
                                            : false
                                        }
                                        value="9"
                                      >
                                        9:00
                                      </option>
                                      <option
                                        selected={
                                          operatingFridayTo === "10"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingFridayFrom) >= 10
                                            ? true
                                            : false
                                        }
                                        value="10"
                                      >
                                        10:00
                                      </option>
                                      <option
                                        selected={
                                          operatingFridayTo === "11"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingFridayFrom) >= 11
                                            ? true
                                            : false
                                        }
                                        value="11"
                                      >
                                        11:00
                                      </option>
                                      <option
                                        selected={
                                          operatingFridayTo === "12"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingFridayFrom) >= 12
                                            ? true
                                            : false
                                        }
                                        value="12"
                                      >
                                        12:00
                                      </option>
                                      <option
                                        selected={
                                          operatingFridayTo === "13"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingFridayFrom) >= 13
                                            ? true
                                            : false
                                        }
                                        value="13"
                                      >
                                        13:00
                                      </option>
                                      <option
                                        selected={
                                          operatingFridayTo === "14"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingFridayFrom) >= 14
                                            ? true
                                            : false
                                        }
                                        value="14"
                                      >
                                        14:00
                                      </option>
                                      <option
                                        selected={
                                          operatingFridayTo === "15"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingFridayFrom) >= 15
                                            ? true
                                            : false
                                        }
                                        value="15"
                                      >
                                        15:00
                                      </option>
                                      <option
                                        selected={
                                          operatingFridayTo === "16"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingFridayFrom) >= 16
                                            ? true
                                            : false
                                        }
                                        value="16"
                                      >
                                        16:00
                                      </option>
                                      <option
                                        selected={
                                          operatingFridayTo === "17"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingFridayFrom) >= 17
                                            ? true
                                            : false
                                        }
                                        value="17"
                                      >
                                        17:00
                                      </option>
                                      <option
                                        selected={
                                          operatingFridayTo === "18"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingFridayFrom) >= 18
                                            ? true
                                            : false
                                        }
                                        value="18"
                                      >
                                        18:00
                                      </option>
                                      <option
                                        selected={
                                          operatingFridayTo === "19"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingFridayFrom) >= 19
                                            ? true
                                            : false
                                        }
                                        value="19"
                                      >
                                        19:00
                                      </option>
                                      <option
                                        selected={
                                          operatingFridayTo === "20"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingFridayFrom) >= 20
                                            ? true
                                            : false
                                        }
                                        value="20"
                                      >
                                        20:00
                                      </option>
                                    </Form.Select>
                                  </Col>
                                </Col>
                                {/* Saturday */}
                                <Col
                                  md="12"
                                  style={{
                                    color: "#A6A6A6",
                                    paddingRight: "10px",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Col lg="2" md="" sm="">
                                    {["checkbox"].map((type) => (
                                      <div
                                        key={`inline-${type}`}
                                        className="mb-3"
                                      >
                                        <Col
                                          lg="12"
                                          md="12"
                                          sm="12"
                                          xs="12"
                                          style={{ marginTop: 10 }}
                                        >
                                          <Form.Check
                                            inline
                                            label="Sat"
                                            name="Saturday"
                                            type={type}
                                            id={`inline-${type}-2`}
                                            style={{ color: netural400 }}
                                            checked={
                                              isSelectedSaturday ? true : false
                                            }
                                            disabled
                                          />
                                        </Col>
                                      </div>
                                    ))}
                                  </Col>
                                  <Col
                                    lg="5"
                                    md=""
                                    sm=""
                                    style={{ paddingRight: "10px" }}
                                  >
                                    <Form.Select
                                      aria-label="Default select example"
                                      style={{ borderRadius: "12px" }}
                                      disabled
                                    >
                                      <option value="0">From</option>
                                      <option
                                        selected={
                                          operatingSaturdayFrom === "6"
                                            ? true
                                            : false
                                        }
                                        value="6"
                                      >
                                        6:00
                                      </option>
                                      <option
                                        selected={
                                          operatingSaturdayFrom === "7"
                                            ? true
                                            : false
                                        }
                                        value="7"
                                      >
                                        7:00
                                      </option>
                                      <option
                                        selected={
                                          operatingSaturdayFrom === "8"
                                            ? true
                                            : false
                                        }
                                        value="8"
                                      >
                                        8:00
                                      </option>
                                      <option
                                        selected={
                                          operatingSaturdayFrom === "9"
                                            ? true
                                            : false
                                        }
                                        value="9"
                                      >
                                        9:00
                                      </option>
                                      <option
                                        selected={
                                          operatingSaturdayFrom === "10"
                                            ? true
                                            : false
                                        }
                                        value="10"
                                      >
                                        10:00
                                      </option>
                                      <option
                                        selected={
                                          operatingSaturdayFrom === "11"
                                            ? true
                                            : false
                                        }
                                        value="11"
                                      >
                                        11:00
                                      </option>
                                      <option
                                        selected={
                                          operatingSaturdayFrom === "12"
                                            ? true
                                            : false
                                        }
                                        value="12"
                                      >
                                        12:00
                                      </option>
                                      <option
                                        selected={
                                          operatingSaturdayFrom === "13"
                                            ? true
                                            : false
                                        }
                                        value="13"
                                      >
                                        13:00
                                      </option>
                                      <option
                                        selected={
                                          operatingSaturdayFrom === "14"
                                            ? true
                                            : false
                                        }
                                        value="14"
                                      >
                                        14:00
                                      </option>
                                      <option
                                        selected={
                                          operatingSaturdayFrom === "15"
                                            ? true
                                            : false
                                        }
                                        value="15"
                                      >
                                        15:00
                                      </option>
                                      <option
                                        selected={
                                          operatingSaturdayFrom === "16"
                                            ? true
                                            : false
                                        }
                                        value="16"
                                      >
                                        16:00
                                      </option>
                                      <option
                                        selected={
                                          operatingSaturdayFrom === "17"
                                            ? true
                                            : false
                                        }
                                        value="17"
                                      >
                                        17:00
                                      </option>
                                      <option
                                        selected={
                                          operatingSaturdayFrom === "18"
                                            ? true
                                            : false
                                        }
                                        value="18"
                                      >
                                        18:00
                                      </option>
                                      <option
                                        selected={
                                          operatingSaturdayFrom === "19"
                                            ? true
                                            : false
                                        }
                                        value="19"
                                      >
                                        19:00
                                      </option>
                                      <option
                                        selected={
                                          operatingSaturdayFrom === "20"
                                            ? true
                                            : false
                                        }
                                        value="20"
                                      >
                                        20:00
                                      </option>
                                    </Form.Select>
                                  </Col>
                                  <Col
                                    lg="5"
                                    md=""
                                    sm=""
                                    style={{ paddingLeft: "10px" }}
                                  >
                                    <Form.Select
                                      aria-label="Default select example"
                                      style={{ borderRadius: "12px" }}
                                      disabled
                                    >
                                      <option value="0">To</option>

                                      <option
                                        selected={
                                          operatingSaturdayTo === "6"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingSaturdayFrom) >= 6
                                            ? true
                                            : false
                                        }
                                        value="6"
                                      >
                                        6:00
                                      </option>
                                      <option
                                        selected={
                                          operatingSaturdayTo === "7"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingSaturdayFrom) >= 7
                                            ? true
                                            : false
                                        }
                                        value="7"
                                      >
                                        7:00
                                      </option>
                                      <option
                                        selected={
                                          operatingSaturdayTo === "8"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingSaturdayFrom) >= 8
                                            ? true
                                            : false
                                        }
                                        value="8"
                                      >
                                        8:00
                                      </option>
                                      <option
                                        selected={
                                          operatingSaturdayTo === "9"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingSaturdayFrom) >= 9
                                            ? true
                                            : false
                                        }
                                        value="9"
                                      >
                                        9:00
                                      </option>
                                      <option
                                        selected={
                                          operatingSaturdayTo === "10"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingSaturdayFrom) >= 10
                                            ? true
                                            : false
                                        }
                                        value="10"
                                      >
                                        10:00
                                      </option>
                                      <option
                                        selected={
                                          operatingSaturdayTo === "11"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingSaturdayFrom) >= 11
                                            ? true
                                            : false
                                        }
                                        value="11"
                                      >
                                        11:00
                                      </option>
                                      <option
                                        selected={
                                          operatingSaturdayTo === "12"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingSaturdayFrom) >= 12
                                            ? true
                                            : false
                                        }
                                        value="12"
                                      >
                                        12:00
                                      </option>
                                      <option
                                        selected={
                                          operatingSaturdayTo === "13"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingSaturdayFrom) >= 13
                                            ? true
                                            : false
                                        }
                                        value="13"
                                      >
                                        13:00
                                      </option>
                                      <option
                                        selected={
                                          operatingSaturdayTo === "14"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingSaturdayFrom) >= 14
                                            ? true
                                            : false
                                        }
                                        value="14"
                                      >
                                        14:00
                                      </option>
                                      <option
                                        selected={
                                          operatingSaturdayTo === "15"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingSaturdayFrom) >= 15
                                            ? true
                                            : false
                                        }
                                        value="15"
                                      >
                                        15:00
                                      </option>
                                      <option
                                        selected={
                                          operatingSaturdayTo === "16"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingSaturdayFrom) >= 16
                                            ? true
                                            : false
                                        }
                                        value="16"
                                      >
                                        16:00
                                      </option>
                                      <option
                                        selected={
                                          operatingSaturdayTo === "17"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingSaturdayFrom) >= 17
                                            ? true
                                            : false
                                        }
                                        value="17"
                                      >
                                        17:00
                                      </option>
                                      <option
                                        selected={
                                          operatingSaturdayTo === "18"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingSaturdayFrom) >= 18
                                            ? true
                                            : false
                                        }
                                        value="18"
                                      >
                                        18:00
                                      </option>
                                      <option
                                        selected={
                                          operatingSaturdayTo === "19"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingSaturdayFrom) >= 19
                                            ? true
                                            : false
                                        }
                                        value="19"
                                      >
                                        19:00
                                      </option>
                                      <option
                                        selected={
                                          operatingSaturdayTo === "20"
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          parseInt(operatingSaturdayFrom) >= 20
                                            ? true
                                            : false
                                        }
                                        value="20"
                                      >
                                        20:00
                                      </option>
                                    </Form.Select>
                                  </Col>
                                </Col>
                              </Form.Group>
                            </Col>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </div>
              </Card>
            </div>
          </Collapse>
          <Card
            style={{
              width: "100%",
              borderTopLeftRadius: "12px",
              borderTopRightRadius: "12px",
              marginTop: "2%",
            }}
            onClick={() => setOpenLocation(!openLocation)}
            aria-expanded={openLocation}
            aria-controls="collapseID3"
            className="shadow"
          >
            <div>
              <Row>
                <Col
                  lg="1"
                  md="1"
                  sm="1"
                  xs="1"
                  className="mt-3 mb-3"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <IconContext.Provider value={{ size: 24, color: "black" }}>
                    <IoLocationOutline />
                  </IconContext.Provider>
                </Col>
                <Col
                  lg="8"
                  md="8"
                  sm="8"
                  xs="8"
                  className="mt-3 mb-3"
                  style={{ flexDirection: "column", display: "flex" }}
                >
                  <label
                    style={{
                      fontWeight: "500",
                      fontSize: "16px",
                      color: neturalBlack,
                    }}
                  >
                    Location
                  </label>
                  <label
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      color: netural400,
                    }}
                  >
                    {selectedCity}
                  </label>
                </Col>
              </Row>
            </div>
          </Card>
          <Collapse in={openLocation}>
            <div id="collapseID3">
              <Card
                style={{
                  width: "100%",
                  borderBottomLeftRadius: "12px",
                  borderBottomRightRadius: "12px",
                }}
              >
                <div
                  style={{
                    marginTop: "2%",
                    backgroundColor: "#FFF3DA",
                    padding: 16,
                    marginRight: "5%",
                    marginLeft: "5%",
                  }}
                >
                  <label
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: netural400,
                      marginLeft: 10,
                    }}
                  >
                    Edit this section is not currently possible. You can always
                    create other services in case of changing schedules.
                  </label>
                </div>
                <div style={{ marginBottom: "3%" }}>
                  <SectionTitle
                    title={"Where will your service take place?"}
                    titleStyle={{ marginLeft: "3%" }}
                  />
                  <div
                    style={{
                      marginLeft: "2%",
                    }}
                  >
                    <Form noValidate>
                      <Row>
                        <Col lg="6" md="6" sm="6" xs="6">
                          {["radio"].map((type) => (
                            <div key={`default-${type}`} className="mb-3">
                              <Row>
                                <Col
                                  lg="12"
                                  md="12"
                                  sm="12"
                                  xs="12"
                                  style={{ display: "flex", marginTop: "1%" }}
                                >
                                  <Col lg="1" md="1" sm="1" xs="1">
                                    <Form.Check.Input
                                      type={type}
                                      name="group1"
                                      onClick={() => {
                                        setIsSelectedOption("option1");
                                      }}
                                      checked={
                                        isSelectedOption === "option1"
                                          ? true
                                          : false
                                      }
                                      disabled
                                    />
                                  </Col>
                                  <Col lg="11" md="11" sm="11" xs="11">
                                    <Col lg="12" md="12" sm="12" xs="12">
                                      <lable
                                        style={{
                                          marginLeft: 10,
                                          color: "#1B1C1E",
                                          fontSize: "16px",
                                        }}
                                      >
                                        I’ll need to go to the customer’s
                                        address
                                      </lable>
                                    </Col>
                                    <Col lg="12" md="12" sm="12" xs="12">
                                      <label
                                        style={{
                                          marginLeft: 10,
                                          color: "#545454",
                                          fontSize: "14px",
                                        }}
                                      >
                                        Services that require your presence at
                                        the customer’s address such as cleaning,
                                        painting, plumbing, moving, etc.
                                      </label>
                                    </Col>
                                  </Col>
                                </Col>
                                <Col
                                  lg="12"
                                  md="12"
                                  sm="12"
                                  xs="12"
                                  style={{ display: "flex", marginTop: "3%" }}
                                >
                                  <Col lg="1" md="1" sm="1" xs="1">
                                    <Form.Check.Input
                                      type={type}
                                      name="group1"
                                      onClick={() => {
                                        setIsSelectedOption("option2");
                                      }}
                                      checked={
                                        isSelectedOption === "option2"
                                          ? true
                                          : false
                                      }
                                      disabled
                                    />
                                  </Col>
                                  <Col lg="11" md="11" sm="11" xs="11">
                                    <Col lg="12" md="12" sm="12" xs="12">
                                      <lable
                                        style={{
                                          marginLeft: 10,
                                          color: "#1B1C1E",
                                          fontSize: "16px",
                                        }}
                                      >
                                        I’ll meet the customer at my address
                                        and/or virtually
                                      </lable>
                                    </Col>
                                    <Col lg="12" md="12" sm="12" xs="12">
                                      <label
                                        style={{
                                          marginLeft: 10,
                                          color: "#545454",
                                          fontSize: "14px",
                                        }}
                                      >
                                        Services that are provided at a specific
                                        location and or virtually, such as
                                        medical consultations, meetings,
                                        restaurants, tours etc.
                                      </label>
                                    </Col>
                                    {isSelectedOption === "option2"
                                      ? ["checkbox"].map((type) => (
                                          <div
                                            key={`inline-${type}`}
                                            className="mb-3"
                                          >
                                            <Col
                                              lg="12"
                                              md="12"
                                              sm="12"
                                              xs="12"
                                              style={{ marginTop: 10 }}
                                            >
                                              <Form.Check
                                                inline
                                                label="In person"
                                                name="In person"
                                                type={type}
                                                id={`inline-${type}-2`}
                                                onClick={() => {
                                                  setIsSelectedInPersonOption(
                                                    !isSelectedInPersonOption
                                                  );
                                                }}
                                                checked={
                                                  isSelectedInPersonOption
                                                    ? true
                                                    : false
                                                }
                                                disabled
                                              />
                                            </Col>
                                            <Col
                                              lg="12"
                                              md="12"
                                              sm="12"
                                              xs="12"
                                              style={{ marginTop: 5 }}
                                            >
                                              <Form.Check
                                                inline
                                                label="Online"
                                                name="Online"
                                                type={type}
                                                id={`inline-${type}-1`}
                                                onClick={() => {
                                                  setIsSelectedOnlineOption(
                                                    !isSelectedOnlineOption
                                                  );
                                                }}
                                                checked={
                                                  isSelectedOnlineOption
                                                    ? true
                                                    : false
                                                }
                                                disabled
                                              />
                                            </Col>
                                          </div>
                                        ))
                                      : null}
                                  </Col>
                                </Col>
                                {isError ? (
                                  <Col
                                    lg="12"
                                    md="12"
                                    sm="12"
                                    xs="12"
                                    style={{
                                      display: "flex",
                                      marginTop: "3%",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <label
                                      style={{
                                        fontSize: ".875em",
                                        color: "#dc3545",
                                      }}
                                    >
                                      {" "}
                                      {isError}{" "}
                                    </label>
                                  </Col>
                                ) : null}
                              </Row>
                            </div>
                          ))}
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </div>
                <div
                  style={{ height: "1px", backgroundColor: primaryGrey }}
                ></div>
                <div style={{ marginBottom: "3%" }}>
                  <SectionTitle
                    title={"What’s your location range?"}
                    titleStyle={{ marginLeft: "3%" }}
                  />
                  <div
                    style={{
                      marginLeft: "2%",
                    }}
                  >
                    <Form noValidate validated={validated}>
                      <Row>
                        <Col lg="6" md="6" sm="6" xs="6">
                          <Col md="12" lg="12">
                            <Form.Group style={{ color: "#A6A6A6" }}>
                              <Form.Label>Country / Region</Form.Label>
                              <Form.Control
                                id="basic-typeahead-single"
                                labelKey="name"
                                onChange={setAllCountry}
                                placeholder="Country / Region"
                                value={allCountry}
                                disabled
                              />
                              <Form.Control.Feedback>
                                Please select a valid Country / Region.
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <div style={{ display: "flex", marginTop: "20px" }}>
                            <Col
                              md="6"
                              style={{ color: "#A6A6A6", paddingRight: "10px" }}
                            >
                              <Form.Group>
                                <Form.Label>State / Province</Form.Label>
                                <Form.Control
                                  id="basic-typeahead-single"
                                  labelKey="name"
                                  onChange={setSelectedState}
                                  placeholder="State / Province"
                                  value={selectedState}
                                  disabled
                                />
                                <Form.Control.Feedback>
                                  Please select a valid State / Province.
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>

                            <Col md="6" style={{ color: "#A6A6A6" }}>
                              <Form.Group>
                                <Form.Label>City</Form.Label>
                                <Form.Control
                                  id="basic-typeahead-single"
                                  labelKey="name"
                                  onChange={setSelectedCity}
                                  placeholder="City"
                                  disabled
                                  value={selectedCity}
                                />
                                <Form.Control.Feedback>
                                  Please select a valid City.
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </div>
              </Card>
            </div>
          </Collapse>
        </div>
      </Fragment>
    </div>
  );
};

export default EditService;
