import { Col, Row } from "react-bootstrap";
import { neturalBlack } from "../../constants/color";
import { useLazyQuery } from "@apollo/client";
import { FETCH_USED_PROMO_CODE_USER_LIST } from "../../services/graphql/admin/coupon";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import LoadingSpinner from "../shared/loading-spinner/loading-spinner.component";

const AdminCoupon = ()=>{

    const [isLoading, setIsLoading] = useState(true);
    const [couponList, setCouponList] = useState([])

    const [fetchUsedPromoCodeUserList] = useLazyQuery(FETCH_USED_PROMO_CODE_USER_LIST,{
        fetchPolicy:"no-cache",
        notifyOnNetworkStatusChange:true,
        onCompleted:(data) =>{
            if(data && data.fetchUsedPromoCodeUserList){
                const {totalCount,usedPromoCodeUserList} = data.fetchUsedPromoCodeUserList;
                // console.log(totalCount,usedPromoCodeUserList)
                setCouponList(usedPromoCodeUserList);
            }else{
                setCouponList([])
            }
            setIsLoading(false)
        },
        onError:(error) =>{
            setIsLoading(false);
            toast.error(error.message, {
                style: { width: "450px" },
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored ",
            });
        }
    });

    const getCouponList = async()=>{
        await fetchUsedPromoCodeUserList()
    }

    useEffect(()=>{
        if(isLoading){
            getCouponList();
        }
    },[isLoading])

    return(
        <div className="main-div-right-section">
            <div className="second-section-style">
                <Row>
                    <Col
                        lg="10"
                        md="8"
                        sm="6"
                        xs="6"
                        style={{
                            paddingLeft: "0px",
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <label style={{ fontSize: "22px", fontWeight: "bold" }}>
                            Coupons
                        </label>
                    </Col> 
                    <div style={{ marginTop: "3%" }}>
                        <label style={{ fontSize: "22px", fontWeight: "bold" }}>
                        Used Promo Code User list
                        </label>
                    </div>
                    <Col
                        xl="12"
                        lg="12"
                        md="12"
                        style={{
                            marginTop: "3%",
                            display: "flex",
                            border: "solid 1px #545454",
                        }}
                        >
                            <Col
                            xl="1"
                            lg="1"
                            md="1"
                            style={{
                                borderRight: "solid 1px #545454",
                                paddingTop: "1%",
                                paddingBottom: "1%",
                            }}
                            >
                            <div
                                style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                }}
                            >
                                <label
                                style={{
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    color: neturalBlack,
                                }}
                                >
                                    Sr.No
                                </label>
                            </div>
                            </Col>
                            <Col
                            xl="3"
                            lg="3"
                            md="3"
                            style={{
                                borderRight: "solid 1px #545454",
                                paddingTop: "1%",
                                paddingBottom: "1%",
                            }}
                            >
                            <div
                                style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                }}
                            >
                                <label
                                style={{
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    color: neturalBlack,
                                }}
                                >
                                Customer name
                                </label>
                            </div>
                            </Col>
                            <Col
                            xl="2"
                            lg="2"
                            md="2"
                            style={{
                                borderRight: "solid 1px #545454",
                                paddingTop: "1%",
                                paddingBottom: "1%",
                            }}
                            >
                            <div
                                style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                }}
                            >
                                <label
                                style={{
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    color: neturalBlack,
                                }}
                                >
                                Coupon code
                                </label>
                            </div>
                            </Col>
                            <Col
                            xl="2"
                            lg="2"
                            md="2"
                            style={{
                                borderRight: "solid 1px #545454",
                                paddingTop: "1%",
                                paddingBottom: "1%",
                            }}
                            >
                            <div
                                style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                }}
                            >
                                <label
                                style={{
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    color: neturalBlack,
                                }}
                                >
                                Coupon discount
                                </label>
                            </div>
                            </Col>
                            <Col
                            xl="2"
                            lg="2"
                            md="2"
                            style={{
                                borderRight: "solid 1px #545454",
                                paddingTop: "1%",
                                paddingBottom: "1%",
                            }}
                            >
                            <div
                                style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                }}
                            >
                                <label
                                style={{
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    color: neturalBlack,
                                }}
                                >
                                Amount
                                </label>
                            </div>
                            </Col>
                            <Col
                            xl="2"
                            lg="2"
                            md="2"
                            style={{ paddingTop: "1%", paddingBottom: "1%" }}
                            >
                            <div
                                style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                }}
                            >
                                <label
                                style={{
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    color: neturalBlack,
                                }}
                                >
                                Purchase date
                                </label>
                            </div>
                            </Col>
                    </Col>
                    {isLoading?
                                <Col
                                    xl="12"
                                    lg="12"
                                    md="12"
                                    style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderLeft: "solid 1px #545454",
                                    borderRight: "solid 1px #545454",
                                    borderBottom: "solid 1px #545454",
                                    }}
                                >
                                    <div style={{ marginTop: "3%", marginBottom:"3%" }}>
                                        <LoadingSpinner overlay={false} />
                                    </div>
                                </Col>
                            : couponList.length === 0 ?
                                <Col
                                    xl="12"
                                    lg="12"
                                    md="12"
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        borderLeft: "solid 1px #545454",
                                        borderRight: "solid 1px #545454",
                                        borderBottom: "solid 1px #545454",
                                    }}
                                >
                                    <div style={{ marginTop: "3%", marginBottom:"3%" }}>
                                        Coupons have not been used so far.
                                    </div>
                                </Col>
                            : couponList.map((list, index) => {
                                return (
                                <Col
                                    xl="12"
                                    lg="12"
                                    md="12"
                                    style={{
                                    display: "flex",
                                    borderBottom: "solid 1px #545454",
                                    borderLeft: "solid 1px #545454",
                                    borderRight: "solid 1px #545454",
                                    }}
                                    key={index}
                                >
                                    <Col
                                    xl="1"
                                    lg="1"
                                    md="1"
                                    style={{
                                        borderRight: "solid 1px #545454",
                                        paddingBottom: "1%",
                                        paddingTop: "1%",
                                    }}
                                    >
                                    <div
                                        style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        }}
                                    >
                                        <label
                                        style={{
                                            fontSize: "16px",
                                            color: neturalBlack,
                                        }}
                                        >
                                        {index+1}
                                        </label>
                                    </div>
                                    </Col>
                                    <Col
                                    xl="3"
                                    lg="3"
                                    md="3"
                                    style={{
                                        borderRight: "solid 1px #545454",
                                        paddingBottom: "1%",
                                        paddingTop: "1%",
                                    }}
                                    >
                                    <div
                                        style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        }}
                                    >
                                        <label
                                        style={{
                                            fontSize: "16px",
                                            color: neturalBlack,
                                        }}
                                        >
                                        {list.customerName}
                                        </label>
                                    </div>
                                    </Col>
                                    <Col
                                    xl="2"
                                    lg="2"
                                    md="2"
                                    style={{
                                        borderRight: "solid 1px #545454",
                                        paddingBottom: "1%",
                                        paddingTop: "1%",
                                    }}
                                    >
                                    <div
                                        style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        }}
                                    >
                                        <label
                                        style={{
                                            fontSize: "16px",
                                            color: neturalBlack,
                                        }}
                                        >
                                        {list.promoCode}
                                        </label>
                                    </div>
                                    </Col>
                                    <Col
                                    xl="2"
                                    lg="2"
                                    md="2"
                                    style={{
                                        borderRight: "solid 1px #545454",
                                        paddingBottom: "1%",
                                        paddingTop: "1%",
                                    }}
                                    >
                                    <div
                                        style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        }}
                                    >
                                        <label
                                        style={{
                                            fontSize: "16px",
                                            color: neturalBlack,
                                        }}
                                        >
                                        {list.type === "percentage" ?` ${list.discountedPercentage} %` : `$ ${list.discountedAmount} `}
                                        </label>
                                    </div>
                                    </Col>
                                    <Col
                                    xl="2"
                                    lg="2"
                                    md="2"
                                    style={{
                                        borderRight: "solid 1px #545454",
                                        paddingBottom: "1%",
                                        paddingTop: "1%",
                                    }}
                                    >
                                    <div
                                        style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        }}
                                    >
                                        <label
                                        style={{
                                            fontSize: "16px",
                                            color: neturalBlack,
                                        }}
                                        >
                                        {`$ ${list.discountedAmount} `}
                                        </label>
                                    </div>
                                    </Col>
                                    <Col
                                    xl="2"
                                    lg="2"
                                    md="2"
                                    style={{
                                        paddingBottom: "1%",
                                        paddingTop: "1%",
                                    }}
                                    >
                                    <div
                                        style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        }}
                                    >
                                        <label
                                        style={{
                                            fontSize: "16px",
                                            color: neturalBlack,
                                        }}
                                        >
                                        {list.purchaseDate}
                                        </label>
                                    </div>
                                    </Col>
                                </Col>
                                );
                        })
                    }
                </Row>
            </div>
        </div>
    )
}

export default AdminCoupon;