import { Col, Row } from "react-bootstrap";
import CustomLabel from "../shared/label/custom-label.component";
import { netural400 } from "../../constants/color";

const PageHeaderSection = ({ text, subHeader }) => {
  return (
    <Row>
      <Col
        lg="10"
        md="8"
        sm="6"
        xs="6"
        style={{
          paddingLeft: "0px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <CustomLabel style={{ fontSize: "22px", fontWeight: "bold" }}>
          {text}
        </CustomLabel>
        {subHeader !== "" && (
          <CustomLabel style={{ fontSize: "16px", color: netural400 }}>
            {subHeader}
          </CustomLabel>
        )}
      </Col>
    </Row>
  );
};

export default PageHeaderSection;
