import { IconContext } from "react-icons";
import { IoInformationCircleOutline } from "react-icons/io5";
import CustomLabel from "../shared/label/custom-label.component";

const NoticeBanner = ({ title }) => {
  return (
    <div
      style={{
        marginBottom: "2%",
        backgroundColor: "#FFF3DA",
        padding: 16,
        borderRadius: 12
      }}
    >
      <IconContext.Provider value={{ size: 22, color: "black" }}>
        <IoInformationCircleOutline />
      </IconContext.Provider>
      <CustomLabel
        style={{
          fontSize: "16px",
          fontWeight: "500",
          color: "#545454",
          marginLeft: 10,
        }}
      >
        {title}
      </CustomLabel>
    </div>
  );
};

export default NoticeBanner;
