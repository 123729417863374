import { Card, Col, Collapse, Form, Row } from "react-bootstrap";
import HeaderSection from "../header/header.component";
import SectionTitle from "../section-title/section-title.component";
import { Fragment, useEffect, useState } from "react";
import { IconContext } from "react-icons";
import { IoAddOutline, IoOptionsOutline } from "react-icons/io5";
import {
  netural400,
  neturalBlack,
  primaryGrey,
  primaryOrange,
} from "../../constants/color";
import CustomButton from "../button/button.component";
import { Link, useNavigate, useParams } from "react-router-dom";
import CustomLabel from "../shared/label/custom-label.component";
import {
  monthlyMinimumCycleOptions,
  weeklyMinimumCycleOptions,
} from "../../constants/constant";
import SubscriptionProductListDesign from "../subscription-product-list-design/subscription-product-list-design.component";
import SelectProductModel from "../select-product-model/select-product-model.component";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  DELETE_SELLING_PLAN,
  EDIT_SELLING_PLAN,
  FETCH_SELLING_PLAN_DETAILS,
} from "../../services/graphql/subscription";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import LoadingSpinner from "../shared/loading-spinner/loading-spinner.component";
import ModalPopup from "../modal-popup/modal-popup.component";

const EditSellingPlan = (props) => {
  const params = useParams();
  const { sellingPlanId } = params;
  const navigate = useNavigate();
  const businesprofile = useSelector((state) => state.businesprofile);
  const [openSellingPlanDetail, setOpenSellingPlanDetail] = useState(false);
  const [openSellingPlanProduct, setOpenSellingPlanProduct] = useState(false);
  const [sellingPlanName, setSellingPlanName] = useState("");
  const [productDiscount, setProductDiscount] = useState(0);
  const [sellingPlanFrequency, setSellingPlanFrequency] = useState("Monthly");
  const [minimumBillingCycle, setMinimumBillingCycle] = useState(1);
  const [billingDropDown, setBillingDropDown] = useState([]);
  const [errorFields, setErrorFields] = useState({
    sellingPlanName: "",
  });

  const [selectedProducts, setSelectedProducts] = useState([]);
  const [showAddProductModel, setShowAddProductModel] = useState(false);
  const [isValid, setIsValid] = useState("");
  const [sellingPlanInfo, setSellingPlanInfo] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  let changedFields = [];

  const invertProductDetailsSection = () => {
    setOpenSellingPlanDetail(!openSellingPlanDetail);
    setOpenSellingPlanProduct(false);
  };

  const invertProductVariantSection = () => {
    setOpenSellingPlanProduct(!openSellingPlanProduct);
    setOpenSellingPlanDetail(false);
  };

  const handleDelete = (allProduct) => {
    setSelectedProducts((prevSelectedProduct) =>
      prevSelectedProduct.filter((selected) => selected._id !== allProduct._id)
    );
  };

  useEffect(() => {
    if (sellingPlanFrequency === "Monthly") {
      setBillingDropDown(monthlyMinimumCycleOptions);
    } else if (sellingPlanFrequency === "Weekly") {
      setBillingDropDown(weeklyMinimumCycleOptions);
    }
  }, [sellingPlanFrequency]);

  useEffect(() => {
    getSellingPlanDetails();
  }, []);

  const getSellingPlanDetails = async () => {
    await fetchSellingPlanDetails({
      variables: {
        businessId: businesprofile._id,
        sellingPlanId: atob(sellingPlanId),
      },
    });
  };

  const [fetchSellingPlanDetails, { loading: loadingSellingPlanDetails }] =
    useLazyQuery(FETCH_SELLING_PLAN_DETAILS, {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        if (data && data.fetchSellingPlanDetails) {
          const sellingPlanDetails = data.fetchSellingPlanDetails;
          const { planName, discount, frequencyType, minimumCycle } =
            sellingPlanDetails;
          // console.log("selling plan------------", sellingPlanDetails);
          setSellingPlanInfo(sellingPlanDetails);
          setSelectedProducts(sellingPlanDetails.products);
          setSellingPlanName(planName);
          setProductDiscount(discount);
          setSellingPlanFrequency(
            frequencyType.charAt(0).toUpperCase() + frequencyType.slice(1)
          );
          setMinimumBillingCycle(minimumCycle);
        }
      },
      onError: (error) => {
        toast.error(error.message, {
          style: { width: "450px" },
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored ",
        });
      },
    });

  const [editSellingPlan, { loading: loadingEditSellingPlan }] = useMutation(
    EDIT_SELLING_PLAN,
    {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        if (data && data.editSellingPlan) {
          toast.success("Selling plan updated successfully!", {
            style: { width: "450px" },
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored ",
          });
          navigate("/subscription");
        } else {
          toast.error("Error", {
            style: { width: "450px" },
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored ",
          });
        }
      },
      onError: (error) => {
        // console.log(error, "ERROR==============");
        toast.error(error.message, {
          style: { width: "450px" },
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored ",
        });
      },
    }
  );

  const [deleteSellingPlan, { loading: loadingDeleteSellingPlan }] =
    useMutation(DELETE_SELLING_PLAN, {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        if (data && data.deleteSellingPlan) {
          toast.success(data.deleteSellingPlan.message, {
            style: { width: "450px" },
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored ",
          });
          navigate("/subscription");
        } else {
          toast.error(data.deleteSellingPlan.message, {
            style: { width: "450px" },
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored ",
          });
        }
      },
      onError: (error) => {
        // console.log(error, "ERROR==============");
        toast.error(error.message, {
          style: { width: "450px" },
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored ",
        });
      },
    });

  const checkSelectedProductChange = (mainProductList, selectedProductList) => {
    if (mainProductList.length !== selectedProductList.length) {
      return false;
    }

    const sortedMainProductList = mainProductList
      .slice()
      .sort((a, b) => a._id - b._id);
    const sortedSelectedProductList = selectedProductList
      .slice()
      .sort((a, b) => a._id - b._id);
    return sortedMainProductList.every(
      (item1, index) => item1._id === sortedSelectedProductList[index]._id
    );
  };

  const checkSellingPlan = () => {
    const dbFrequency =
      sellingPlanInfo.frequencyType.charAt(0).toUpperCase() +
      sellingPlanInfo.frequencyType.slice(1);

    // Array to track the changed fields

    // Compare each field and track changes
    if (sellingPlanInfo.planName !== sellingPlanName.trim()) {
      changedFields.push("planName");
    } else {
      // If the field is reverted to the original, remove it from changedFields
      changedFields = changedFields.filter((field) => field !== "planName");
    }

    if (parseFloat(sellingPlanInfo.discount) !== parseFloat(productDiscount)) {
      changedFields.push("discount");
    } else {
      changedFields = changedFields.filter((field) => field !== "discount");
    }

    if (dbFrequency !== sellingPlanFrequency.trim()) {
      changedFields.push("frequencyType");
    } else {
      changedFields = changedFields.filter((field) => field !== "minimumCycle");
    }

    if (
      parseInt(sellingPlanInfo.minimumCycle, 10) !==
      parseInt(minimumBillingCycle, 10)
    ) {
      changedFields.push("minimumCycle");
    } else {
      changedFields = changedFields.filter((field) => field !== "minimumCycle");
    }

    // Check products array
    if (
      !checkSelectedProductChange(sellingPlanInfo.products, selectedProducts)
    ) {
      changedFields.push("products");
    } else {
      changedFields = changedFields.filter((field) => field !== "products");
    }

    // Return the list of changed fields (if any) or false if no changes
    return changedFields.length > 0 ? changedFields : false;
  };

  const setNewValue = (changedFields) => {
    let updatedValue = {};
    // Loop through changedFields array and add corresponding values to updatedValue
    changedFields.forEach((field) => {
      switch (field) {
        case "planName":
          updatedValue.planName = sellingPlanName;
          break;
        case "discount":
          updatedValue.discount = productDiscount
            ? parseFloat(productDiscount)
            : 0.0;
          break;
        case "frequencyType":
          updatedValue.frequencyType = sellingPlanFrequency;
          break;
        case "minimumCycle":
          updatedValue.minimumCycle = parseInt(minimumBillingCycle);
          break;
        case "products":
          updatedValue.products = selectedProducts.map((product) => {
            return {
              id: product._id,
            };
          });
          break;
        default:
          break;
      }
    });
    return updatedValue;
  };

  const handleSubmitSellingPlan = async () => {
    if (selectedProducts.length === 0) {
      setIsValid(true);
      toast.error("Please select any one product to create selling plan.", {
        style: { width: "450px" },
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored ",
      });
    } else {
      const changedFields = checkSellingPlan();

      if (changedFields) {
        // Create an empty object to hold only the changed fields
        const updatedSellingPlanInfo = setNewValue(changedFields);
        // console.log("final object", setNewValue(changedFields), changedFields);
        await editSellingPlan({
          variables: {
            sellingPlanId: atob(sellingPlanId),
            editSellingPlanInput: updatedSellingPlanInfo,
          },
        });
      } else {
        setOpenSellingPlanDetail(false);
        setOpenSellingPlanProduct(false);
      }
    }
  };

  return loadingSellingPlanDetails ||
    loadingEditSellingPlan ||
    loadingDeleteSellingPlan ? (
    <LoadingSpinner overlay />
  ) : (
    <div className="main-div-right-section">
      <div>
        <HeaderSection currentTab={"Selling Plan"} />
        <div className="second-section-style">
          <Row>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{
                marginTop: "2%",
                display: "flex",
                alignItems: "center",
                marginBottom: "2%",
              }}
            >
              <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "3%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: "1%",
                    }}
                  >
                    <Link
                      to={"/subscription"}
                      style={{
                        fontSize: "16px",
                        color: netural400,
                      }}
                    >
                      Selling plans
                    </Link>
                    <CustomLabel
                      style={{
                        marginLeft: "1%",
                        marginRight: "1%",
                        color: neturalBlack,
                      }}
                    >
                      {">"}
                    </CustomLabel>
                    <CustomLabel
                      style={{
                        fontSize: "16px",
                        fontWeight: "600",
                        color: neturalBlack,
                      }}
                    >
                      {sellingPlanName}
                    </CustomLabel>{" "}
                  </div>
                  <CustomLabel
                    style={{
                      fontSize: "22px",
                      fontWeight: "bold",
                      color: neturalBlack,
                    }}
                  >
                    {sellingPlanName}
                  </CustomLabel>{" "}
                </div>
              </Col>
              <Col
                xs={2}
                sm={2}
                md={2}
                lg={2}
                xl={2}
                style={{ display: "flex", justifyContent: "end" }}
              >
                <CustomButton
                  title={"Delete selling plan"}
                  buttonType={"delete selling"}
                  deleteIcon
                  handleButtonClick={() => {
                    setShowDeleteModal(true);
                  }}
                />
              </Col>
            </Col>
          </Row>
          <Card
            style={
              openSellingPlanDetail
                ? {
                    width: "100%",
                    borderTopLeftRadius: "12px",
                    borderTopRightRadius: "12px",
                  }
                : {
                    width: "100%",
                    borderRadius: "12px",
                  }
            }
            aria-expanded={openSellingPlanDetail}
            aria-controls="collapseID"
            className="shadow"
          >
            <div>
              <Row
                onClick={() => {
                  if (!openSellingPlanDetail) {
                    invertProductDetailsSection();
                  }
                }}
              >
                <Col
                  lg="1"
                  md="1"
                  sm="1"
                  xs="1"
                  className="mt-3 mb-3"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    if (openSellingPlanDetail) {
                      invertProductDetailsSection();
                    }
                  }}
                >
                  <IconContext.Provider value={{ size: 24, color: "black" }}>
                    <IoOptionsOutline />
                  </IconContext.Provider>
                </Col>
                <Col
                  lg="8"
                  md="8"
                  sm="8"
                  xs="8"
                  className="mt-3 mb-3"
                  style={{ flexDirection: "column", display: "flex" }}
                  onClick={() => {
                    if (openSellingPlanDetail) {
                      invertProductDetailsSection();
                    }
                  }}
                >
                  <label
                    style={{
                      fontWeight: "500",
                      fontSize: "16px",
                      color: neturalBlack,
                    }}
                  >
                    Selling plan details
                  </label>
                  <label
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      color: netural400,
                    }}
                  >
                    {sellingPlanName}
                  </label>
                </Col>
                {openSellingPlanDetail ? (
                  <Fragment>
                    <Col
                      lg="1"
                      md="1"
                      sm="1"
                      xs="1"
                      className="mt-3 mb-3"
                      style={{ flexDirection: "column", display: "flex" }}
                    >
                      <CustomButton
                        buttonType="secondary"
                        title={"Cancel"}
                        handleButtonClick={invertProductDetailsSection}
                      />
                    </Col>
                    <Col
                      lg="2"
                      md="2"
                      sm="2"
                      xs="2"
                      className="mt-3 mb-3"
                      style={{ flexDirection: "column", display: "flex" }}
                    >
                      <CustomButton
                        title={"Save and close"}
                        customStyle={{ marginTop: "0px", padding: "8px" }}
                        handleButtonClick={handleSubmitSellingPlan}
                      />
                    </Col>
                  </Fragment>
                ) : null}
              </Row>
            </div>
          </Card>
          <Collapse in={openSellingPlanDetail}>
            <div id="collapseID">
              <Card
                style={{
                  width: "100%",
                  borderBottomLeftRadius: "12px",
                  borderBottomRightRadius: "12px",
                }}
              >
                <div style={{ marginTop: "3%" }}>
                  <Row>
                    <Col
                      lg="1"
                      md="1"
                      sm="1"
                      xs="1"
                      className="mt-3 mb-3"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    ></Col>
                    <Col lg="11" md="11" sm="11" xs="11">
                      <SectionTitle title={"General Information"} />
                    </Col>
                  </Row>
                  <div>
                    <Form noValidate validated={false}>
                      <Row className="mb-3">
                        <Col xl="12" md="12" lg="12">
                          <div style={{ display: "flex" }}>
                            <Col
                              lg="1"
                              md="1"
                              sm="1"
                              xs="1"
                              className="mt-3 mb-3"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            ></Col>
                            <Col lg="11" md="11" sm="11" xs="11">
                              {/* <div style={{ display: "flex", marginTop: "20px" }}> */}
                              <Col
                                xl="5"
                                md="5"
                                lg="5"
                                style={{
                                  color: "#A6A6A6",
                                  paddingRight: "10px",
                                }}
                              >
                                <Form.Group
                                  controlId="validationCustom01"
                                  style={{ color: netural400 }}
                                >
                                  <Form.Label>Selling plan name</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    placeholder="Selling plan name"
                                    value={sellingPlanName}
                                    onChange={(value) => {
                                      const name = value.target.value;
                                      if (name.trim().length !== 0) {
                                        setErrorFields({ sellingPlanName: "" });
                                        setSellingPlanName(name);
                                      } else {
                                        setSellingPlanName("");
                                      }
                                    }}
                                    isInvalid={!!errorFields.sellingPlanName}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {errorFields.sellingPlanName}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Col>
                              <Col
                                md="5"
                                lg="5"
                                xl="5"
                                style={{ marginTop: "4%" }}
                              >
                                <Form.Group
                                  controlId="validationCustom01"
                                  style={{ color: netural400 }}
                                >
                                  <Form.Label>Discount percent</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    inputMode="numeric"
                                    placeholder="Discount percent"
                                    value={productDiscount}
                                    onChange={(event) => {
                                      const newValue =
                                        event.target.value.trim();
                                      // Check if the input is empty and handle backspace/delete key
                                      if (newValue === "" || newValue === ".") {
                                        setProductDiscount("");
                                      } else if (
                                        /^[0-9]+(\.[0-9]*)?$/.test(newValue) &&
                                        parseFloat(newValue) >= 0 &&
                                        parseFloat(newValue) <= 100
                                      ) {
                                        setProductDiscount(newValue);
                                      } else {
                                        setProductDiscount(productDiscount);
                                      }
                                    }}
                                    step="0.01"
                                    minLength={1}
                                    maxLength={5}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Please provide a valid product discount.
                                  </Form.Control.Feedback>
                                  <CustomLabel
                                    style={{
                                      fontSize: "12px",
                                      color: netural400,
                                    }}
                                  >
                                    Determine discount for recurring orders. You
                                    can set 0% for no promotions.
                                  </CustomLabel>
                                </Form.Group>
                              </Col>
                              {/* </div> */}
                            </Col>
                          </div>
                          <div
                            style={{
                              height: "1px",
                              backgroundColor: primaryGrey,
                              marginLeft: "-1%",
                              marginRight: "-1%",
                              marginTop: "3%",
                            }}
                          ></div>
                          <Row>
                            <Col
                              lg="1"
                              md="1"
                              sm="1"
                              xs="1"
                              className="mt-3 mb-3"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            ></Col>
                            <Col lg="11" md="11" sm="11" xs="11">
                              <SectionTitle
                                title={"Frequency"}
                                subtitle={
                                  "The interval options your customers can choose among order deliveries. E.g.: Every 2 months, every 3 weeks."
                                }
                              />
                              <Col
                                md="5"
                                lg="5"
                                xl="5"
                                style={{ marginTop: "4%" }}
                              >
                                <div style={{ marginTop: "3%" }}>
                                  {["Monthly", "Weekly"].map((label, index) => (
                                    <div
                                      key={index}
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        border: "solid 1px #dadada",
                                        borderRadius: "12px",
                                        padding: "3%",
                                        marginBottom: "5%",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Form.Check
                                          inline
                                          name="group1"
                                          type={"radio"}
                                          id={index}
                                          checked={
                                            label === sellingPlanFrequency
                                          }
                                          onChange={() => {
                                            setSellingPlanFrequency(label);
                                          }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                          }}
                                          onClick={() => {
                                            setSellingPlanFrequency(label);
                                            setMinimumBillingCycle(1);
                                          }}
                                        >
                                          <CustomLabel
                                            style={{
                                              fontSize: "16px",
                                              color: neturalBlack,
                                            }}
                                          >
                                            {label}
                                          </CustomLabel>
                                          <CustomLabel
                                            style={{
                                              fontSize: "14px",
                                              color: netural400,
                                              marginTop: "2%",
                                            }}
                                          >
                                            {label === "Weekly"
                                              ? "Customers can choose from 1-8 weeks. After 8 week period, the options are: every 12,16,20 up to 24 weeks."
                                              : "Customers can choose recurring orders from every 1 month up to 6 months"}
                                          </CustomLabel>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </Col>
                            </Col>
                          </Row>
                          <div
                            style={{
                              height: "1px",
                              backgroundColor: primaryGrey,
                              marginLeft: "-1%",
                              marginRight: "-1%",
                              marginTop: "3%",
                            }}
                          ></div>
                          <Row>
                            <Col
                              lg="1"
                              md="1"
                              sm="1"
                              xs="1"
                              className="mt-3 mb-3"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            ></Col>
                            <Col lg="11" md="11" sm="11" xs="11">
                              <SectionTitle
                                title={"Billing cycle restrictions"}
                              />
                              <Col
                                md="5"
                                lg="5"
                                xl="5"
                                style={{ marginTop: "4%" }}
                              >
                                <Form.Group style={{ color: "#A6A6A6" }}>
                                  <Form.Label>
                                    Minimum billing cycles
                                  </Form.Label>
                                  <Form.Select
                                    aria-label="Default select example"
                                    size="sm"
                                    style={{
                                      borderRadius: "12px",
                                      border: "1px solid #dadada",
                                      fontSize: "1rem",
                                    }}
                                    value={minimumBillingCycle}
                                    onChange={(value) => {
                                      let selectedDropdownValue =
                                        value.target.value;
                                      setMinimumBillingCycle(
                                        selectedDropdownValue
                                      );
                                    }}
                                  >
                                    {billingDropDown.map((number, index) => {
                                      return (
                                        <option key={index} value={number}>
                                          {number}
                                        </option>
                                      );
                                    })}
                                  </Form.Select>
                                  <CustomLabel
                                    style={{
                                      fontSize: "12px",
                                      color: netural400,
                                    }}
                                  >
                                    The minimum recurring orders before your
                                    customer can cancel the subscription. The
                                    default value is one.
                                  </CustomLabel>
                                </Form.Group>
                              </Col>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </div>
                <div
                  style={{ height: "1px", backgroundColor: primaryGrey }}
                ></div>
                <Row style={{ justifyContent: "end" }}>
                  <Col
                    lg="1"
                    md="1"
                    sm="1"
                    xs="1"
                    className="mt-3 mb-3"
                    style={{ flexDirection: "column", display: "flex" }}
                  >
                    <CustomButton
                      buttonType="secondary"
                      title={"Cancel"}
                      handleButtonClick={invertProductDetailsSection}
                    />
                  </Col>
                  <Col
                    lg="2"
                    md="2"
                    sm="2"
                    xs="2"
                    className="mt-3 mb-3"
                    style={{ flexDirection: "column", display: "flex" }}
                  >
                    <CustomButton
                      title={"Save and close"}
                      customStyle={{ marginTop: "0px", padding: "8px" }}
                      handleButtonClick={handleSubmitSellingPlan}
                    />
                  </Col>
                </Row>
                {/* </Col> */}
                {/* </Row> */}
              </Card>
            </div>
          </Collapse>

          <Card
            style={
              openSellingPlanProduct
                ? {
                    width: "100%",
                    borderTopLeftRadius: "12px",
                    borderTopRightRadius: "12px",
                    marginTop: "2%",
                  }
                : {
                    width: "100%",
                    borderRadius: "12px",
                    marginTop: "2%",
                  }
            }
            aria-expanded={openSellingPlanProduct}
            aria-controls="collapseID"
            className="shadow"
          >
            <div>
              <Row
                onClick={() => {
                  if (!openSellingPlanProduct) {
                    invertProductVariantSection();
                  }
                }}
              >
                <Col
                  lg="1"
                  md="1"
                  sm="1"
                  xs="1"
                  className="mt-3 mb-3"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    if (openSellingPlanProduct) {
                      invertProductVariantSection();
                    }
                  }}
                >
                  <IconContext.Provider value={{ size: 24, color: "black" }}>
                    <IoOptionsOutline />
                  </IconContext.Provider>
                </Col>
                <Col
                  lg="8"
                  md="8"
                  sm="8"
                  xs="8"
                  className="mt-3 mb-3"
                  style={{ flexDirection: "column", display: "flex" }}
                  onClick={() => {
                    if (openSellingPlanProduct) {
                      invertProductVariantSection();
                    }
                  }}
                >
                  <label
                    style={{
                      fontWeight: "500",
                      fontSize: "16px",
                      color: neturalBlack,
                    }}
                  >
                    Products and variants
                  </label>
                </Col>
                {openSellingPlanProduct ? (
                  <Fragment>
                    <Col
                      lg="1"
                      md="1"
                      sm="1"
                      xs="1"
                      className="mt-3 mb-3"
                      style={{ flexDirection: "column", display: "flex" }}
                    >
                      <CustomButton
                        buttonType="secondary"
                        title={"Cancel"}
                        handleButtonClick={invertProductVariantSection}
                      />
                    </Col>
                    <Col
                      lg="2"
                      md="2"
                      sm="2"
                      xs="2"
                      className="mt-3 mb-3"
                      style={{ flexDirection: "column", display: "flex" }}
                    >
                      <CustomButton
                        title={"Save and close"}
                        customStyle={{ marginTop: "0px", padding: "8px" }}
                        handleButtonClick={handleSubmitSellingPlan}
                      />
                    </Col>
                  </Fragment>
                ) : null}
              </Row>
            </div>
          </Card>
          <Collapse in={openSellingPlanProduct}>
            <div id="collapseID">
              <Card
                style={{
                  width: "100%",
                  borderBottomLeftRadius: "12px",
                  borderBottomRightRadius: "12px",
                }}
              >
                <Row>
                  <Col
                    lg="1"
                    md="1"
                    sm="1"
                    xs="1"
                    className="mt-3 mb-3"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  ></Col>
                  <Col lg="11" md="11" sm="11" xs="11">
                    <div style={{ marginTop: "3%" }}>
                      <SectionTitle
                        title={"Products and variants"}
                        subtitle={
                          "Click below to add products and variants to this selling plan"
                        }
                      />
                      <Row>
                        <Col
                          xl={5}
                          lg={5}
                          md={5}
                          style={{
                            marginBottom: "3%",
                            paddingLeft: "0px",
                          }}
                        >
                          <div
                            style={{
                              border: "solid 1px #dadada",
                              borderRadius: "12px",
                              display: "flex",
                              flexDirection: "column",
                              paddingTop: "2%",
                              paddingBottom: "2%",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                borderBottom: "solid 1px #dadada",
                                paddingBottom: "2%",
                              }}
                            >
                              <CustomLabel
                                style={{
                                  paddingLeft: "2%",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  color: netural400,
                                }}
                              >
                                Showing {selectedProducts.length} products
                              </CustomLabel>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  paddingRight: "2%",
                                  alignItems: "center",
                                }}
                                onClick={() => {
                                  setShowAddProductModel(true);
                                }}
                              >
                                <IoAddOutline size={16} color={primaryOrange} />
                                <CustomLabel
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    color: primaryOrange,
                                  }}
                                >
                                  Add product
                                </CustomLabel>
                              </div>
                            </div>
                            <div style={{ marginTop: "2%" }}>
                              {selectedProducts.map((allProduct, index) => {
                                return (
                                  <SubscriptionProductListDesign
                                    allProduct={allProduct}
                                    products={selectedProducts}
                                    index={index}
                                    showCheckBox={false}
                                    showPrice={false}
                                    showDelete={true}
                                    showEdit={false}
                                    imageColValue={10}
                                    handleDelete={handleDelete}
                                  />
                                );
                              })}
                            </div>
                          </div>
                          {isValid && (
                            <div>
                              <CustomLabel
                                style={{ fontSize: "14px", color: "red" }}
                              >
                                Please select any one product to create selling
                                plan.
                              </CustomLabel>
                            </div>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
                <div
                  style={{ height: "1px", backgroundColor: primaryGrey }}
                ></div>
                <Row style={{ justifyContent: "end" }}>
                  <Col
                    lg="1"
                    md="1"
                    sm="1"
                    xs="1"
                    className="mt-3 mb-3"
                    style={{ flexDirection: "column", display: "flex" }}
                  >
                    <CustomButton
                      buttonType="secondary"
                      title={"Cancel"}
                      handleButtonClick={invertProductDetailsSection}
                    />
                  </Col>
                  <Col
                    lg="2"
                    md="2"
                    sm="2"
                    xs="2"
                    className="mt-3 mb-3"
                    style={{ flexDirection: "column", display: "flex" }}
                  >
                    <CustomButton
                      title={"Save and close"}
                      customStyle={{ marginTop: "0px", padding: "8px" }}
                      handleButtonClick={handleSubmitSellingPlan}
                    />
                  </Col>
                </Row>
              </Card>
            </div>
          </Collapse>
        </div>
      </div>
      {showAddProductModel && (
        <SelectProductModel
          show={showAddProductModel}
          onHide={() => {
            setShowAddProductModel(false);
          }}
          handleAddProduct={(selectedProduct) => {
            setSelectedProducts(selectedProduct);
            setShowAddProductModel(false);
            setIsValid(false);
          }}
          selectedProducts={selectedProducts}
          sellingPlanId={atob(sellingPlanId)}
        />
      )}
      {showDeleteModal && (
        <ModalPopup
          show={showDeleteModal}
          onHide={() => {
            setShowDeleteModal(false);
          }}
          onCancelPress={() => {
            setShowDeleteModal(false);
          }}
          onSubmit={async () => {
            setShowDeleteModal(false);
            await deleteSellingPlan({
              variables: {
                sellingPlanId: atob(sellingPlanId),
              },
            });
          }}
          buttonType={"Delete"}
          title={"Delete selling plan"}
          body={
            "Deleting this selling plan won’t impact your current subscriptions. Your subscriptions will remain the same as agreed at the time of purchase."
          }
          submitButton={"Delete selling plan"}
        />
      )}
    </div>
  );
};

export default EditSellingPlan;
