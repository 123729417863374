import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarFooter,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from "cdbreact";
import {
  NavLink,
  useNavigate,
  Outlet,
  useLocation,
  Link,
} from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearUser } from "../../actions/userActions";
import { clearBusinesProfile } from "../../actions/businessProfileActions";
import { auth } from "../../firebase";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BsGrid, BsBag, BsCart3, BsCalendar4, BsRepeat } from "react-icons/bs";
import { IconContext } from "react-icons";
import {
  IoBriefcaseOutline,
  IoLogOutOutline,
  IoSettingsOutline,
  IoTrendingUpSharp,
} from "react-icons/io5";
import { primaryOrange } from "../../constants/color";
import { ReactComponent as MYLogo } from "../../assets/logo.svg";
import { clearSales } from "../../actions/salesAction";
import { useSelector } from "react-redux";
import { isAdminUser } from "../../reducers/user/user.selector";
import { Fragment } from "react";

const Navigation = () => {
  const navigation = useNavigate();
  const isUserAdmin = useSelector(isAdminUser);
  const dispatch = useDispatch();
  const signOut = () => {
    auth
      .signOut()
      .then(() => {
        // console.log("Successfully logout");
        navigation("/login");
        dispatch(clearBusinesProfile());
        dispatch(clearUser());
        dispatch(clearSales());
      })
      .catch((error) => {
        toast.info("Something went wrong, Please try again later", {
          style: { left: -50, width: "400px" },
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };
  const location = useLocation(); // Get the current location
  const currentPath = location.pathname;

  // Function to determine if a tab should be highlighted
  const isTabActive = (tabPath) => {
    return currentPath.startsWith(tabPath);
  };
  return (
    <div style={{ display: "flex", height: "100vh" }}>
      <div
        style={{
          // display: "flex",
          // height: "100vh",
          overflow: "scroll initial",
          // position: "fixed",
        }}
      >
        <CDBSidebar textColor="#fff" backgroundColor={primaryOrange}>
          <CDBSidebarHeader
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              borderBottom: "0px",
            }}
            // prefix={<i className="fa fa-bars fa-large"></i>}
          >
            {/* <div
                style={{
                  alignItems:"center",  
                  justifyContent: "center",
                  display: "flex",
                  marginTop: "15%",
                }}
              > */}
            <MYLogo
              // // src="../images/SideMenuLogo.png"
              // alt="My..."
              style={{ size: 20 }}
            />
            {/* </div> */}
          </CDBSidebarHeader>

          <CDBSidebarContent className="sidebar-content">
            <CDBSidebarMenu>
              <NavLink
                exact="true"
                activeclassname="activeClicked"
                isActive={() => isTabActive("/overview")}
              >
                <CDBSidebarMenuItem
                  className="netural-white"
                  style={
                    isTabActive("/overview")
                      ? { backgroundColor: "#C24E00", borderRadius: 12 }
                      : null
                  }
                >
                  <IconContext.Provider value={{ size: 24, color: "white" }}>
                    <BsGrid />
                  </IconContext.Provider>
                  <Link style={{ marginLeft: 20 }}>Overview</Link>
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact="true"
                to={"/bookings"}
                activeclassname="activeClicked"
                isActive={() => isTabActive("/bookings")}
              >
                <CDBSidebarMenuItem
                  className="netural-white"
                  style={
                    isTabActive("/bookings")
                      ? { backgroundColor: "#C24E00", borderRadius: 12 }
                      : null
                  }
                >
                  <IconContext.Provider value={{ size: 24, color: "white" }}>
                    <BsCalendar4 />
                  </IconContext.Provider>
                  <Link to={"/bookings"} style={{ marginLeft: 20 }}>Calendar</Link>
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact="true"
                to={"/products"}
                activeclassname="activeClicked"
                isActive={() => isTabActive("/products")}
              >
                <CDBSidebarMenuItem
                  className="netural-white"
                  style={
                    isTabActive("/products")
                      ? { backgroundColor: "#C24E00", borderRadius: 12 }
                      : null
                  }
                >
                  <IconContext.Provider value={{ size: 24, color: "white" }}>
                    <BsBag />
                  </IconContext.Provider>
                  <Link to={"/products"} style={{ marginLeft: 20 }}>Products</Link>
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact="true"
                to={"/services"}
                isActive={() => isTabActive("/services")}
                activeclassname="activeClicked"
              >
                <CDBSidebarMenuItem
                  className="netural-white"
                  style={
                    isTabActive("/services")
                      ? { backgroundColor: "#C24E00", borderRadius: 12 }
                      : null
                  }
                >
                  <IconContext.Provider value={{ size: 24, color: "white" }}>
                    <BsCart3 />
                  </IconContext.Provider>
                  <Link to={"/services"} style={{ marginLeft: 20 }}>Services</Link>
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact="true"
                to={"/sales"}
                isActive={() => isTabActive("/sales")}
                activeclassname="activeClicked"
              >
                <CDBSidebarMenuItem
                  className="netural-white"
                  style={
                    isTabActive("/sales")
                      ? { backgroundColor: "#C24E00", borderRadius: 12 }
                      : null
                  }
                >
                  <IconContext.Provider value={{ size: 24, color: "white" }}>
                    <IoTrendingUpSharp />
                  </IconContext.Provider>
                  <Link to={"/sales"} style={{ marginLeft: 20 }}>Sales</Link>
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact="true"
                to={"/subscription"}
                isActive={() => isTabActive("/subscription")}
                activeclassname="activeClicked"
              >
                <CDBSidebarMenuItem
                  className="netural-white"
                  style={
                    isTabActive("/subscription")
                      ? { backgroundColor: "#C24E00", borderRadius: 12 }
                      : null
                  }
                >
                  <IconContext.Provider value={{ size: 24, color: "white" }}>
                    <BsRepeat />
                  </IconContext.Provider>
                  <Link to={"/subscription"} style={{ marginLeft: 20 }}>Subscription</Link>
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact="true"
                to={"/businessInfo"}
                isActive={() => isTabActive("/businessInfo")}
                activeclassname="activeClicked"
              >
                <CDBSidebarMenuItem
                  className="netural-white"
                  style={
                    isTabActive("/businessInfo")
                      ? { backgroundColor: "#C24E00", borderRadius: 12 }
                      : null
                  }
                >
                  <IconContext.Provider value={{ size: 24, color: "white" }}>
                    <IoBriefcaseOutline />
                  </IconContext.Provider>
                  <Link to={"/businessInfo"} style={{ marginLeft: 20 }}>Business info</Link>
                </CDBSidebarMenuItem>
              </NavLink>
              <NavLink
                exact="true"
                isActive={() => isTabActive("/preferences")}
                activeclassname="activeClicked"
              >
                <CDBSidebarMenuItem
                  className="netural-white"
                  style={
                    isTabActive("preferences")
                      ? { backgroundColor: "#C24E00", borderRadius: 12 }
                      : null
                  }
                >
                  <IconContext.Provider value={{ size: 24, color: "white" }}>
                    <IoSettingsOutline />
                  </IconContext.Provider>
                  <Link style={{ marginLeft: 20 }}>Preferences</Link>
                </CDBSidebarMenuItem>
              </NavLink>

              {/* <NavLink
                exact="true"
                isActive={() => isTabActive("/membership")}
                activeclassname="activeClicked"
              >
                <CDBSidebarMenuItem
                  className="netural-white"
                  style={
                    isTabActive("membership")
                      ? { backgroundColor: "#C24E00", borderRadius: 12 }
                      : null
                  }
                >
                  <IconContext.Provider value={{ size: 24, color: "white" }}>
                    <BsGrid />
                  </IconContext.Provider>
                  <label style={{ marginLeft: 20 }}>Membership</label>
                </CDBSidebarMenuItem>
              </NavLink> */}
              {isUserAdmin ? (
                <NavLink
                  exact="true"
                  to={"/admin"}
                  isActive={() => isTabActive("/admin")}
                  activeclassname="activeClicked"
                >
                  <CDBSidebarMenuItem
                    className="netural-white"
                    style={
                      isTabActive("/admin")
                        ? { backgroundColor: "#C24E00", borderRadius: 12 }
                        : null
                    }
                  >
                    <IconContext.Provider value={{ size: 24, color: "white" }}>
                      <BsGrid />
                    </IconContext.Provider>
                    <Link to={"/admin"} style={{ marginLeft: 20 }}>Admin</Link>
                  </CDBSidebarMenuItem>
                </NavLink>
              ) : (
                <Fragment></Fragment>
              )}
            </CDBSidebarMenu>
          </CDBSidebarContent>
          <div
            style={{ marginBottom: "50px" }}
            onClick={() => {
              signOut();
            }}
          >
            <CDBSidebarFooter style={{ marginLeft: "30px" }}>
              <IconContext.Provider value={{ size: 24, color: "white" }}>
                <IoLogOutOutline />
              </IconContext.Provider>
              <Link
                to={() => {
                  signOut();
                }}
                style={{
                  marginLeft: 20,
                  color: "white",
                  textDecoration: "none",
                }}
              >
                Sign out
              </Link>
            </CDBSidebarFooter>
          </div>
        </CDBSidebar>
      </div>
      <Outlet />
    </div>
  );
};

export default Navigation;
