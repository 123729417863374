import { gql } from "@apollo/client";
//this is for fetching business profile whole data
export const GET_BUSINESS_PROFILE = gql`
  query businessProfileData($id: String!) {
    businessProfileData(id: $id) {
      _id
      user_id
      name
      email
      phone
      logo
      location
      team
      description
      subscriptionName
      commission
    }
  }
`;

export const FETCH_BUSINESS_VERIFICATION_INFO = gql`
  query fetchBusinessVerification($business_id: String) {
    fetchBusinessVerification(business_id: $business_id) {
      legalBusinessName
      businessNumber
      taxNumber
      taxRate
      location
      rejectionReason
      termsAndConditions
      privacyPolicy
      verification
    }
  }
`;

export const HANDLE_BUSINESS_VERIFICATION_REQUEST = gql`
  mutation handleBusinessVerificationRequest(
    $verificationInfo: VerificationInfo
    $verificationType: String
  ) {
    handleBusinessVerificationRequest(
      verificationInfo: $verificationInfo
      verificationType: $verificationType
    ) {
      success
      message
      verificationStatus
    }
  }
`;
