import { React, useState, useEffect } from "react";
import { Col, Image, Row } from "react-bootstrap";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useLazyQuery } from "@apollo/client";
import { LOGIN_CHECK } from "../../services/graphql/auth";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../../firebase";
import VerifyOTP from "../../components/verify-otp/verify-otp.component";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomButton from "../../components/button/button.component";

const Login = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isPhoneValid, setPhoneValid] = useState(false);
  const [formattedNumber, setFormattedNumber] = useState("");
  const [showOTPVerificationComponent, setShowOTPVerificationComponent] =
    useState(false);
  const [confirmationResult, setConfirmationResult] = useState("");
  const [portalLoginCheck, { loading, error, data }] = useLazyQuery(
    LOGIN_CHECK,
    {
      fetchPolicy: "no-cache",
    }
  );
  const verifyInDatabase = async () => {
    let finalPhone = phoneNumber.replace("+", "");
    await portalLoginCheck({
      variables: {
        phone: `+${finalPhone.toString()}`,
      },
    });
  };
  const generateRecaptcha = () => {
    let appVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {},
      },
      auth
    );
    onSignIn(appVerifier);
  };
  const onSignIn = (appVerifier) => {
   // console.log("onSignInmethod", appVerifier);
    signInWithPhoneNumber(auth, `+${phoneNumber}`, appVerifier)
      .then((confirmationResult) => {
        setConfirmationResult(confirmationResult);
        setShowOTPVerificationComponent(true);
        // console.log(confirmationResult);
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  useEffect(() => {
    if (data && !loading) {
      if (data.portalLoginCheck.completed) {
        generateRecaptcha();
      } else {
        if (
          data.portalLoginCheck.message === "Business Account does not exist"
        ) {
          toast.error("Please Create Business Account first", {
            style: { width: "400px" },
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light ",
          });
        } else {
          toast.error(data.portalLoginCheck.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      }
    }
    return () => clearInterval();
  }, [data, loading, error]);
  const handleEditPhoneNumber = () => {
    setShowOTPVerificationComponent(false);
  };

  const handleSignInClick = ()=>{
      if (isPhoneValid) {
        verifyInDatabase();
      }
  }
  return (
    <div>
      <div style={{ width: "97%",marginLeft: '3%', marginTop: '1%' }}>
        <Image
          src="./images/FinalLogo.svg"
          alt="My..."
          style={{ marginRight: 20, height: "6%", width: "6%" }}
        />
      </div>

      <div
        style={{
          width: "100%",
          height: "80vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundImage: 'url("./images/LoginBackground.png")',
          backgroundSize: "cover",
          overflow: "hidden",
        }}
      >
        {!showOTPVerificationComponent ? (
          <>
            <Row style={{ justifyContent: "center" }}>
              <Col lg="10" md="10" sm="10" xs="10" className="Auth-form">
                <div
                  style={{
                    marginLeft: "24px",
                    marginRight: "24px",
                    marginTop: "40px",
                  }}
                >
                  <label
                    className="netural-black"
                    style={{ fontSize: "24px", fontWeight: "bold" }}
                  >
                    Welcome back!
                  </label>
                  <label className="netural-grey" style={{ fontSize: "16px" }}>
                    Sign in to dashboard with the phone number registered.
                  </label>
                  <div style={{ marginTop: "32px" }}>
                    <label
                      className="netural-grey"
                      style={{ fontSize: "16px", marginBottom: "8px" }}
                    >
                      Phone number
                    </label>
                    <PhoneInput
                      country={"US"}
                      value={phoneNumber}
                      enableSearch
                      onChange={(value) => {
                        if (value && isValidPhoneNumber(value)) {
                          setPhoneNumber(value);
                          setFormattedNumber(value);
                          setPhoneValid(true);
                        } else {
                          setPhoneValid(false);
                        }
                      }}
                      international
                      defaultCountry="US"
                    />
                  </div>
                  <div
                    style={{
                      marginTop: "24px",
                      marginBottom: "40px",
                    }}
                  >
                    <CustomButton title={"Sign in"} buttonType={"validation"} isValid={isPhoneValid} handleButtonClick={handleSignInClick}/>
                  </div>
                </div>
              </Col>
            </Row>
            <div id="recaptcha-container"></div>
          </>
        ) : (
          <VerifyOTP
            phoneNumber={phoneNumber}
            formattedNumber={formattedNumber}
            confirmationResult={confirmationResult}
            handleEditPhoneNumber={handleEditPhoneNumber}
          />
        )}
      </div>
    </div>
  );
}

export default Login;
