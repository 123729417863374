import { gql } from "@apollo/client";
export const FETCH_SERVICE_LIST = gql`
  query fetchBusinessServiceList($businessId: String) {
    fetchBusinessServiceList(businessId: $businessId) {
      success
      message
      services {
        serviceId
        title
        photoUri
        duration {
          hours
          minutes
        }
        expired
      }
    }
  }
`;

export const FETCH_SERVICE_FOR_EDIT = gql`
  query fetchServiceForEdit($serviceId: String) {
    fetchServiceForEdit(serviceId: $serviceId) {
      message
      service {
        serviceId
        title
        description
        startDate
        endDate
        serviceType
        serviceInPerson
        serviceOnline
        price
        currency
        discount
        location {
          cityName
          countryName
          stateName
        }
        bookingRestriction
        capacity
        maxCapacity
        duration {
          hours
          minutes
        }
        breakTime {
          endTime
          startTime
        }
        schedule {
          available
          dayOfWeek
          endTime
          startTime
        }
        medias {
          height
          key
          mediaType
          uri
          width
        }
      }
    }
  }
`;

export const CREATE_PORTAL_SERVICE = gql`
  mutation createPortalService($createServiceInput: createServiceInput) {
    createPortalService(createServiceInput: $createServiceInput) {
      success
      message
    }
  }
`;

export const EDIT_PORTAL_SERVICE = gql`
  mutation editPortalService($editServiceInput: editServiceInput) {
    editPortalService(editServiceInput: $editServiceInput) {
      success
      message
    }
  }
`;

export const DELETE_PORTAL_SERVICE = gql`
  mutation deletePortalService($serviceId: String) {
    deletePortalService(serviceId: $serviceId) {
      message
      success
    }
  }
`;
